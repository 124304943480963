export const base = {
  "src.components.routes.pages.Root.title": "NutritionPro",
  "src.components.routes.pages.BackBoxes.title": "NutritionPro",
  "error.phone.min": "Telefonní číslo musí mít alespoň 12 znaků",
  "src.components.atoms.PricePerDay.title": "Cena za menu",
  "src.components.atoms.PricePerDay.description": "(bez dopravy)",
  "src.components.molecules.OnlineOrderFirstStepSidebar.promoCode":
    "Máš promokód?",
  "src.components.molecules.OnlineOrderFirstStepSidebar.order":
    "Můžeš ho vyplnit na konci objednávky.",
  "error.name.min": "Jméno číslo musí mít alespoň 3 znaků",

  "src.components.atoms.PricePerDay.from": "od",
  "src.components.atoms.PricePerDay.symbol": "{price} Kč",
  "src.components.molecules.PriceCard,symbol": "{price} Kč",
  "src.components.molecules.PriceCard.promo": "Promokód „{promo}“",
  "src.components.molecules.PriceCard.delete": "Odebrat",
  "src.components.molecules.PriceCard.btn": "Pokračovat",
  "src.components.molecules.PriceCard.title": "Cena objednávky",
  "src.components.routes.pages.DietBrno.title": "Brně",
  "src.components.routes.pages.DietHradec.title": "Hradci Králové",
  "src.components.routes.pages.DietJablonec.title": "Jablonci nad Nisou",
  "src.components.routes.pages.DietKladno.title": "Kladně",
  "src.components.routes.pages.DietSredniCechi.title": "ve Středních Čechách",

  "src.components.routes.pages.DietLiberec.title": " Liberci",
  "src.components.organisms.Footer.partner.btn": "Stát se partnerem",
  "src.components.organisms.ThankYouModal.succsesTitle":
    "Vaše žádost byla úspěšně přijata",
  "src.components.organisms.ThankYouModal.failTitle":
    "Něco se pokazilo. Prosím zkuste to znovu",
  "src.components.organisms.ThankYouModal.btn": "Zpět na hlavní stránku",
  "src.components.organisms.GiftVoucherModal.titleFirst":
    "Udělejte radost svým",
  "src.components.organisms.GiftVoucherModal.titleSecond": "nejbližším",
  "src.components.organisms.GiftVoucherModal.description":
    "Dárkový voucher od NutritionPro je dárek, který ocení úplně každý",
  "src.components.organisms.forms.GiftVoucherForm.btn": "Odeslat",
  "src.components.organisms.forms.GiftVoucherForm.title":
    "Zanechte nám na sebe kontakt, spojíme se s vámi a pomůžeme vybrat vhodný program",
  "src.components.routes.pages.DietMlda.title": "Mladé Boleslavi",
  "src.components.molecules.DeliveryInfo.title": "Doprava je vždy",
  "src.components.molecules.DeliveryInfo.link": "zdarma",
  "src.components.molecules.OrderRightCard.jidel": "{jidel} chodové menu",
  "src.components.molecules.PromoCodeLink.promoCode": "Vložit promokód",
  "src.components.molecules.OrderRightCard.btn": "Objednat online",
  "src.components.routes.pages.DietParadubice.title": "Pardubicích",
  "src.components.routes.pages.DietPlzen.title": "Plzni",
  "src.components.routes.pages.DietPraha.title": "Praze a okolí",
  "src.components.organisms.ProgramBlock.titleFirst": "Vyber si ",
  "src.components.organisms.ProgramBlock.titleSecond":
    " jeden z našich programů krabičkových diet",
  "src.components.molecules.OrderRightCard.pocrovat": "Pokračovat",
  "src.components.organisms.ProgramBlock.description":
    "Užij si pestrý jídelníček bez omezení. V rámci všech našich programů máš možnost chody libovolně vyměnit.",
  "src.components.routes.pages.Gdpr.title":
    "Základní informace o zpracování osobních údajů a kontakty",
  "src.components.molecules.CallMeCard.title":
    " Potřebujete poradit s výběrem?",
  "src.components.molecules.CallMeCard.description": "Zavolejte nám.",
  "src.components.organisms.ContactForm.Checkbox.personalData.title":
    "Souhlasím se zpracováním",
  "src.components.organisms.ContactForm.Checkbox.personalData.link":
    " osobních údajů",

  "src.components.organisms.ContactForm.Checkbox.terms.title":
    "Souhlas se zpracováním osobních údajů pro marketingové účely ",
  "src.components.routes.pages.NotFound.title": "NotFound",
  "src.components.routes.pages.OnlineOrder.title": "OnlineOrder",
  "src.components.routes.pages.Terms.title": "Všeobecné obchodní podmínky",
  "src.components.atoms.GiftVoucher.title": "Dárkový voucher",
  "src.components.atoms.WorkingHours.days": "Po - Pá",
  "src.components.molecules.Menu.count": "Volných pozic",
  "src.components.atoms.Copyright.text":
    "Copyright ©{currentYear} NutritionPro s.r.o",
  "src.components.molecules.SocialMedia": "Sledujte nás na sociálních sítích:",
  "src.components.organisms.Footer.location.title": "Adresa",
  "src.components.organisms.Footer.phone.title": "Telefon",
  "src.components.organisms.Footer.email.title": "E-mail",
  "src.components.organisms.Footer.diet": "Nejlépe hodnocená krabičková dieta.",
  "src.components.organisms.Footer.links": "Odkazy",
  "src.components.organisms.Footer.program": "Programy",
  "src.components.organisms.Footer.menu": "Jídelníček",
  "src.components.organisms.Footer.price": "Cena",
  "src.components.organisms.Footer.career": "Kariéra",
  "src.components.organisms.Footer.documents": "Dokumenty",
  "src.components.organisms.Footer.personalData": "Nakládání s osobními údaji ",
  "src.components.organisms.Footer.terms": "Obchodní podmínky",
  "src.components.organisms.Footer.cookies": "Pravidla používání cookies",
  "src.components.organisms.Footer.settings": "Otevřít nastavení cookies",
  "src.components.organisms.Footer.media": "Pro média, partnery a firmy",
  "src.components.organisms.Footer.partner": "Email: {email}",
  "src.components.organisms.DesktopHeader.price": "Spočítej si cenu",
  "src.components.organisms.DesktopHeader.career": "Kariéra",
  "src.components.organisms.forms.redBtn": "Mám zájem",
  "src.components.organisms.DesktopHeader.blog": "Blog",
  "src.components.molecules.DeliveryItem.razvoz": "Rozvoz",
  "src.components.molecules.DeliveryItem.praha": "Krabičková dieta Praha",
  "src.components.molecules.DeliveryItem.brno": "Krabičková dieta Brno",
  "src.components.molecules.DeliveryItem.plzen": "Krabičková dieta Plzeň",

  "src.components.molecules.DeliveryItem.liberec": "Krabičková dieta Liberec",
  "src.components.molecules.DeliveryItem.hradec":
    "Krabičková dieta Hradec Králové",
  "src.components.molecules.DeliveryItem.paradubice":
    "Krabičková dieta Pardubice",
  "src.components.molecules.DeliveryItem.sredniCech":
    "Krabičková dieta Střední Čechy",
  "src.components.molecules.DeliveryItem.jablonec": "Krabičková dieta Jablonec",
  "src.components.molecules.DeliveryItem.mlda":
    "Krabičková dieta Mladá Boleslav",
  "src.components.organisms.MobileHeader.career": "Kariéra",
  "src.components.organisms.MobileHeader.program": "Dietní programy",
  "src.components.organisms.MobileHeader.customerPortal": "Klientská zóna",
  "src.components.organisms.ProgramBlock.meat.title": "NutritionPro Classic",
  "src.components.molecules.OnlineMenuSlider.meat.title":
    "NutritionPro Classic",
  "src.components.organisms.ProgramBlock.meat.description":
    "Naše klasika představující dokonalé spojení lahodné chuti s optimální nutriční hodnotou.",
  "src.components.organisms.ProgramBlock.light.title": "NutritionPro Light",

  "src.components.organisms.ProgramBlock.vege.description":
    "Jsi vegetarián nebo si jen chceš dát pauzu od masa? Pak je toto menu pro tebe. Neobsahuje totiž maso, a to včetně ryb.",
  "src.components.organisms.ProgramBlock.vege.title": "NutritionPro Vege",
  "src.components.molecules.OnlineMenuSlider.vege.title": "NutritionPro Vege",

  "src.components.organisms.ProgramBlock.light.description":
    "Odlehčené menu bez svačin za super cenu, ať už ti svačiny nevyhovují, nebo si je chceš doplnit podle svého.",
  "src.components.organisms.ProgramBlock.fish.title": "Menu bez ryb",
  "src.components.molecules.OnlineMenuSlider.fish.title": "Menu bez ryb",

  "src.components.organisms.ProgramBlock.fish.description":
    "Myslíme na vše a víme, že ryby nejsou pro všechny to pravé. Pokud se chceš vyhnout rybám i výrobkům z nich, sáhni po tomto menu.",
  "src.components.organisms.ProgramBlock.combo.title": "NutritionPro kombo",

  "src.components.organisms.ProgramBlock.combo.description":
    "Jedná se o dvouchodové menu (oběd a večeři), které si můžeš zvolit dle vlastních preferencí.",
  "src.components.molecules.ProgramCard.jidel": "{jidel} jídel",
  "src.components.molecules.ProgramCard.jidla": "{jidel} jídla",
  "src.components.molecules.ProgramCard.link": "Více informací",
  "src.components.molecules.ProgramCard.price": "od {price} Kč",
  "src.components.atoms.OnlineProgramCard.price": "od {price} Kč",
  "src.components.molecules.ProgramCard.day": "den",
  "src.components.atoms.OnlineProgramCard.day": "den",
  "src.components.molecules.ProgramCard.orderBtn": "Objednat",
  "src.components.molecules.ProgramCard.modalbtn": "Zkonzultovat",
  "src.components.organisms.MobileHeader.menu": "Jídelníček",
  "src.components.organisms.MobileHeader.price": "Cena",
  "src.components.organisms.MobileHeader.blog": "Blog",
  "src.components.organisms.MultiOrder.titleFirst": "Společná objednávka",
  "src.components.organisms.MultiOrder.titleSecond": "se slevou až 20 %",
  "src.components.organisms.MultiOrder.discount":
    "První objednávku pro {count} máte se slevou",
  "src.components.organisms.MultiOrder.order":
    "Objednej si krabičky s partnerem, rodinou či kolegy a získej výhodnější cenu.",
  "src.components.organisms.HowWorks.titleFirst": "Jak ",
  "src.components.organisms.HowWorks.titleSecond":
    "funguje naše krabičková dieta?",
  "src.components.organisms.HowWorks.daily":
    "Nastavíme tvůj denní příjem podle tvých cílů",
  "src.components.organisms.HowWorks.algorithm":
    "Speciální algoritmus sestaví jídelníček na míru",
  "src.components.organisms.HowWorks.cooking": "Jídlo uvaříme",
  "src.components.organisms.HowWorks.delivery": "Dovezeme ti domů či do práce ",
  "src.components.organisms.HowWorks.goal":
    "Vychutnáš si plnohodnotné jídlo, které ti pomůže na cestě za tvými cíli.",
  "src.components.organisms.Delivery.titleFirst":
    "Spolehlivě a flexibilně již ",
  "src.components.organisms.Delivery.titleSecond":
    " v 9 městech České republiky",
  "src.components.organisms.Delivery.titleSecondMob": " v 9 městech",
  "src.components.organisms.Delivery.titleSecondMobLast": "České republiky",
  "src.components.organisms.Delivery.description":
    "Ověř si, jestli vozíme i přímo k tobě nebo ti bude vyhovovat jedno z našich 16 odběrných míst.",
  "src.components.organisms.Delivery.praha": "Praha",
  "src.components.organisms.Delivery.brno": "Brno",
  "src.components.organisms.Delivery.plzen": "Plzeň",
  "src.components.organisms.Delivery.liberec": "Liberec",
  "src.components.organisms.Delivery.hradec": "Hradec Králové",
  "src.components.organisms.Delivery.paradubice": "Pardubice",
  "src.components.organisms.Delivery.jablonec": "Jablonec",
  "src.components.organisms.Delivery.kladno": "Střední Čechy",
  "src.components.organisms.Delivery.mlda": "Mladá Boleslav",
  "src.components.organisms.Delivery.praha.time":
    "Dovoz ve večerních hodinách mezi 17:00 - 22:00",
  "src.components.organisms.Delivery.brno.time":
    "Dovoz ve večerních hodinách mezi 18:00-23:00",
  "src.components.organisms.Delivery.plzen.time":
    "Dovoz ve večerních hodinách mezi 17:00 - 22:00 ",
  "src.components.organisms.Delivery.liberec.time":
    "Dovoz ve večerních hodinách mezi 17:00-22:00",
  "src.components.organisms.Delivery.hradec.time":
    "Dovoz ve večerních hodinách mezi 16:30-22:00",
  "src.components.organisms.Delivery.paradubice.time":
    "Dovoz ve večerních hodinách mezi 16:30-22:00",
  "src.components.organisms.Delivery.kladno.time":
    "Dovoz ve večerních hodinách mezi 17:00 - 22:00",
  "src.components.organisms.Delivery.jablonec.time":
    "Dovoz ve večerních hodinách mezi 18:00-22:00",
  "src.components.organisms.Delivery.mlda.time":
    "Dovoz ve večerních hodinách mezi 16:00-22:00",
  "src.components.organisms.Delivery.praha.replace":
    "Změna času a místa možná ještě v den dovozu do 10:00",
  "src.components.organisms.Delivery.brno.replace":
    "Změna času a místa možná ještě v den dovozu do 10:00",
  "src.components.organisms.Delivery.plzen.replace":
    "Změna času a místa možná ještě v den dovozu do 10:00",
  "src.components.organisms.Delivery.liberec.replace":
    "Změna času a místa možná ještě v den dovozu do 10:00",
  "src.components.organisms.Delivery.hradec.replace":
    "Změna času a místa možná ještě v den dovozu do 10:00",
  "src.components.organisms.Delivery.paradubice.replace":
    "Změna času a místa možná ještě v den dovozu do 10:00",
  "src.components.organisms.Delivery.kladno.replace":
    "Změna času a místa možná ještě v den dovozu do 10:00",
  "src.components.organisms.Delivery.jablonec.replace":
    "Změna času a místa možná ještě v den dovozu do 10:00",
  "src.components.organisms.Delivery.mlda.replace":
    "Změna času a místa možná ještě v den dovozu do 10:00",
  "src.components.organisms.Delivery.praha.point": "10 odběrných míst",
  "src.components.molecules.DeliveryCard.delivery": "Dovoz zdarma",
  "src.components.organisms.HowCook.titleFirst": "Proč ",
  "src.components.organisms.HowCook.titleSecond": "NutritionPro?",
  "src.components.organisms.HowCook.titleSecond.praha":
    " krabičková dieta Praha?",

  "src.components.organisms.HowCook.description":
    "Podívej se na unikátní proces přípravy našich skvělých jídel.",
  "src.components.organisms.HowCook.area.title": "Moderní výrobní plocha",
  "src.components.organisms.HowCook.area.descritpion":
    "Používáme nejmodernější technologie k co nejšetrnějšímu zpracování surovin.",
  "src.components.organisms.HowCook.suppliers.title": "Nejlepší dodavatelé",
  "src.components.organisms.HowCook.suppliers.description":
    "Všichni naši dodavatelé jsou dlouhodobě prověřeni.",
  "src.components.organisms.HowCook.ingredients.title":
    "Jen ty nejkvalitnější suroviny",
  "src.components.organisms.HowCook.ingredients.description":
    "Každou jednotlivou dodávku kontrolujeme, aby se ke zpracování dostaly jen ty nejkvalitnější suroviny.",
  "src.components.organisms.HowCook.chefs.title": "Zkušení kuchaři",
  "src.components.organisms.HowCook.chefs.descripton":
    "Naši kuchaři vaří podle nejnovějších trendů a postupů, denně pro vás připravují až 5 000 pokrmů.",
  "src.components.organisms.Vege.titleFirst": "Vegetariánské",
  "src.components.organisms.Vege.titleSecond": "menu",
  "src.components.organisms.Vege.description":
    "Zkus to jinak a ochutnej naše bezmasé pokrmy!",
  "src.components.molecules.GoogleMark.text":
    "{count}+ kladných zákaznických hodnocení",
  "src.components.organisms.Vege.list.title":
    "Již nyní si můžeš pochutnat na našem bezmasém menu.",
  "src.components.organisms.Vege.list.description1":
    "Nutričně vyvážený jídelníček",
  "src.components.organisms.Vege.list.description2":
    "Dostatečné množství bílkovin",
  "src.components.organisms.Vege.list.description3": "Chutné a pestré menu",
  "src.components.organisms.Vege.list.description4": "Prémiové suroviny",
  "src.components.molecules.Partners.text": "Oficiální partneři",
  "src.components.organisms.RateBlock.titleFirst":
    "{greenTitle} hodnocená krabičková dieta",
  "src.components.routes.pages.DietKladno.deliveryTitle":
    "{greenTitle} krabičková dieta v Kladno",
  "src.components.routes.pages.DietKladno.greenTitile": "Nejflexibilnejší",
  "src.components.routes.pages.DietPraha.deliveryTitle":
    "{greenTitle} krabičková dieta v Praze",
  "src.components.routes.pages.DietPraha.greenTitile": "Nejflexibilnejší",
  "src.components.routes.pages.DietBrno.deliveryTitle":
    "{greenTitle} krabičková dieta v Brně",
  "src.components.routes.pages.DietBrno.greenTitile": "Nejflexibilnejší",
  "src.components.routes.pages.DietPlzen.deliveryTitle":
    "{greenTitle} krabičková dieta v Plzni",
  "src.components.routes.pages.DietPlzen.greenTitile": "Nejflexibilnejší",
  "src.components.routes.pages.DietLiberec.deliveryTitle":
    "{greenTitle} krabičková dieta v Liberci",
  "src.components.routes.pages.DietLiberec.greenTitile": "Nejflexibilnejší",
  "src.components.routes.pages.DietHradec.deliveryTitle":
    "{greenTitle} krabičková dieta v Hradci Králové",
  "src.components.routes.pages.DietHradec.greenTitile": "Nejflexibilnejší",
  "src.components.routes.pages.DietHradecKralove.deliveryTitle":
    "{greenTitle} krabičková dieta v Hradci Králové",
  "src.components.routes.pages.DietHradecKralove.greenTitile":
    "Nejflexibilnejší",
  "src.components.routes.pages.DietParadubice.deliveryTitle":
    "{greenTitle} krabičková dieta v Pardubicích",
  "src.components.routes.pages.DietParadubice.greenTitile": "Nejflexibilnejší",
  "src.components.routes.pages.DietSredniCechi.deliveryTitle":
    "{greenTitle} krabičková dieta ve Středních Čechách",
  "src.components.routes.pages.DietSredniCechi.greenTitile": "Nejflexibilnejší",
  "src.components.routes.pages.DietJablonec.deliveryTitle":
    "{greenTitle} krabičková dieta v Jablonci nad Nisou",
  "src.components.routes.pages.DietJablonec.greenTitile": "Nejflexibilnejší",
  "src.components.routes.pages.DietJablonecNisou.deliveryTitle":
    "{greenTitle} krabičková dieta v Jablonci nad Nisou",
  "src.components.routes.pages.DietJablonecNisou.greenTitile":
    "Nejflexibilnejší",
  "src.components.routes.pages.DietMldaBoleslav.deliveryTitle":
    "{greenTitle} krabičková dieta v Mladé Boleslavi",
  "src.components.routes.pages.DietMldaBoleslav.greenTitile":
    "Nejflexibilnejší",
  "src.components.routes.pages.DietMlda.deliveryTitle":
    "{greenTitle} krabičková dieta v Mladé Boleslavi",
  "src.components.routes.pages.DietMlda.greenTitile": "Nejflexibilnejší",

  "src.components.organisms.ComplateMenu.titleFirst": " nezávazně na 2 dny",
  "src.components.organisms.ComplateMenu.titleSecond":
    "Vyzkoušej krabičkovou dietu ",
  "src.components.atoms.Review.seeMore": "Zobrazit vše",
  "src.components.organisms.Vege.form.title":
    "Naše vege krabičky už na tebe čekají.",
  "src.components.organisms.Vege.form.description":
    "Vyplň svůj telefon  a my se ti ozveme.",
  "src.components.organisms.ComplateMenu.description":
    "Zanech nám na sebe kontakt, v pracovní době se ti do dvou hodin ozveme a během 10 minut vše individuálně domluvíme.",
  "src.components.organisms.ComplateMenuView.day": "DEN",
  "src.components.organisms.ComplateMenu.price": "{count} Kč",
  "src.components.organisms.SpecialistBlock.titleFirst": "Vždy pod dohledem",
  "src.components.organisms.SpecialistBlock.titleSecond": "odborníka na výživu",
  "src.components.organisms.ComplateMenu.priceTitle": "Super cena",
  "src.components.organisms.RateBlock.greenTitle": "Nejlépe ",
  "src.components.molecules.BenefitCard.textFirst": "Vybírej až ze ",
  "src.components.organisms.ReviewBlock.titleFirst": "Skuteční zákazníci ",
  "src.components.organisms.ReviewBlock.titleSecond": "řeknou více než my.",
  "src.components.organisms.ReviewBlock.description":
    "Ke každému zákazníkovi přistupujeme individuálně, abychom zajistili maximální spokojenost.",
  "src.components.organisms.SpecialistBlock.sepicalist.text":
    "Expert nutritionist",
  "src.components.molecules.PhoneInput.label": "Tvůj telefon",
  "src.component.organisms.CheckDelivery.faild":
    "Je nám líto! Zatím v této lokalitě nejsme.",
  "src.component.organisms.CheckDelivery.success": "Na vaši adresu rozvážíme",
  "src.component.organisms.CheckDelivery.placenholder":
    "Vyplň ulici, č.p. a město.",
  "src.components.organisms.SpecialistBlock.sepicalist.title":
    "Jitka Dokoupilová",
  "src.components.molecules.BenefitCard.benefit": "4 jídel ",
  "src.components.molecules.BenefitCard.textLast": "na každý chod.",
  "src.components.organisms.RateBlock.redBtn": "Mám zájem",
  "src.components.organisms.RateBlock.redBtnCity": "Vybrat program",

  "src.components.organisms.HowCook.redBtn": "Objednat online",
  "src.components.organisms.forms.BannerForm.name": "Jméno a příjmení",
  "src.components.organisms.forms.CallMeForm.name": "Jméno a příjmení",
  "src.components.organisms.CheckDelivery.title":
    "Zkontroluj si, zda doručujeme i k tobě.",
  "src.components.molecules.AddressCheck.confirm": "Potvrdit",
  "src.components.organisms.SpecialistBlock.textFirst":
    "Jsem {specialist} v NutritionPro a výživou prostě žiju! Vystudovala jsem analýzu potravin na VŠCHT a nutriční terapii na 1. lékařské fakultě. Klientům radím už více {years} a za tu dobu mi jich rukama prošlo přes {count}.",
  "src.components.organisms.SpecialistBlock.specialist": "nutriční poradce",
  "src.components.organisms.SpecialistBlock.years": "než 15 let",
  "src.components.organisms.SpecialistBlock.count": "1500",

  "src.components.organisms.CheckDelivery.confirm": "Potvrdit",
  "src.components.organisms.Faq.titleFirst": "Často kladené",
  "src.components.organisms.SpecialistBlock.arrowText":
    "{specialist} s odborníkem na výživu je {price} každého programu",
  "src.components.organisms.SpecialistBlock.arrowText.sepcialist": "Konzultace",
  "src.components.organisms.SpecialistBlock.arrowText.price": "zahrnuta v ceně",
  "src.components.organisms.Faq.titleSecond": "dotazy",
  "src.components.organisms.Faq.menu.title": "Jídelníček na míru",
  "src.components.organisms.Faq.materials.title": "Suroviny",
  "src.components.organisms.Faq.delivery.title": "Rozvoz a platba",
  "src.components.organisms.Faq.client.title": "Změny a zákaznická podpora",

  "src.components.organisms.Faq.consumption.title": "Způsob konzumace",
  "src.components.organisms.Faq.menu.subTitle1":
    "V čem je naše krabičková dieta jiná?",
  "src.components.organisms.SpecialistBlock.textSecond":
    "Máš krabičky, ale nevíš, jak jíst o víkendu? Váháš, jaký pitný režim se ke krabičkování hodí? Chceš se ujistit, jaký příjem energie potřebuješ? Máš jiné výživové otázky, nebo chceš teprve začít? Pokud něco nevíš, jsem tu pro tebe. A budeš mít jasno.",
  "src.components.organisms.Faq.menu.content1":
    "Naše {info} se vyznačuje individuálním přístupem. Věříme totiž, že každý člověk je jedinečný a potřebuje specifický kalorický příjem, aby dosáhl svých cílů zdravou cestou. Menu pro 70kg ženu, která chce zhubnout, bude vypadat úplně jinak než pro 90kg muže, který se snaží nabrat svalovou hmotu. Naše {info} je navržena přesně tak, aby odpovídala vašim individuálním potřebám a cílům, což běžné krabičkové diety často opomíjejí.",
  "src.components.organisms.Faq.menu.subTitle2":
    "Kdo sestavuje jídelníček pro krabičkovou dietu?",
  "src.components.organisms.Faq.menu.content2":
    "Jídelníček pro naši krabičkovou dietu je sestavován naším speciálním algoritmem, který pečlivě zohledňuje potřeby vašeho těla i vaše cíle. Na vyváženost a složení jednotlivých jídel dohlíží naše nutriční poradkyně, Jitka Dokoupilová, abyste měli oporu na cestě za vašimi cíli.",
  "src.components.organisms.Faq.menu.subTitle3":
    "Jak vypadá jídelníček v rámci krabičkové diety?",
  "src.components.organisms.Faq.menu.content3":
    "Naše krabičková dieta je založena na zásadách vyvážené a udržitelné stravy. Čeká vás 500 různých, ale vždy chutných a pestrých jídel, která neustále obměňujeme. Stále hledáme nové možnosti a kombinace, aby se vám naše jídla neomrzela.",
  "src.components.organisms.Faq.materials.subTitle1":
    "Jaké suroviny používáte pro krabičkovou dietu?",
  "src.components.organisms.Faq.materials.content1":
    "Pro naši {info} používáme pouze kvalitní a čerstvé suroviny od prověřených českých dodavatelů. Kvalita a čerstvost surovin je pro nás základem zdravého stravování.",
  "src.components.organisms.Faq.materials.subTitle2":
    "Jsou jídla v rámci krabičkové diety čerstvá?",
  "src.components.organisms.Faq.materials.content2":
    "Ano, všechna jídla v rámci naší {info} jsou připravována z čerstvých surovin, které kupujeme v den přípravy. Po uvaření jsou jídla šokově zchlazena a hermeticky zabalena, což zajišťuje jejich maximální čerstvost.",
  "src.components.organisms.Faq.materials.subTitle3":
    "Kdy připravujete jídla pro krabičkovou dietu?",
  "src.components.organisms.Faq.materials.content3":
    "Příprava jídel pro naši {info} probíhá v den rozvozu. Jídla připravujeme v neděli na pondělí a úterý, v úterý na středu a čtvrtek, a ve čtvrtek na pátek a sobotu.",
  "src.components.organisms.Faq.materials.subTitle4":
    "Máte v rámci krabičkové diety i dezerty?",
  "src.components.organisms.Faq.materials.content4":
    "Ano, naše {info} zahrnuje i výborné dezerty, které jsou přesně spočítány tak, aby zapadly do vašeho osobního jídelníčku.",
  "src.components.organisms.Faq.delivery.subTitle1":
    "Jak dlouho dopředu musím nahlásit vynechání dovozu krabičkové diety?",
  "src.components.organisms.Faq.delivery.content1":
    "Jakékoliv změny objednávky v rámci naší krabičkové diety, včetně pozastavení dovozu, je nutné nahlásit nejpozději tři pracovní dny před plánovaným doručením do 11:00. Tato doba se odvíjí od zajištění těch nejčerstvějších potravin pro přípravu všech jídel na přesný počet zákazníků.",
  "src.components.organisms.Faq.delivery.subTitle2":
    "Mohu zrušit pouze jeden den v rámci krabičkové diety?",
  "src.components.organisms.Faq.delivery.content2":
    "Pokud se vaše objednávka {info} vztahuje na dva dny, je možné zrušit pouze celou dvoudenní objednávku. Zrušení pouze jednoho dne není možné.",
  "src.components.organisms.Faq.delivery.subTitle3":
    "Jak je stanovena cena krabičkové diety?",
  "src.components.organisms.Faq.delivery.content3":
    "Cena naší krabičkové diety je nastavena individuálně, dle vašeho stravovacího plánu, denního kalorického příjmu a vašich osobních cílů. Podrobnější kalkulaci si můžete nechat vypočítat v naší online kalkulačce.",
  "src.components.organisms.Faq.delivery.subTitle4":
    "Jak probíhá platba za krabičkovou dietu?",
  "src.components.organisms.Faq.delivery.content4":
    "Po dokončení objednávky naší {info} vám zašleme fakturu na váš e-mail. Můžete také zvolit platbu kartou či stravenkami. ",
  "src.components.organisms.Faq.delivery.subTitle5":
    "V čem jsme jiní než ostatní krabičkové diety?",
  "src.components.organisms.Faq.delivery.content5":
    "Věříme v individuální přístup. Každý z nás je originál a každý z nás potřebuje jiný kalorický příjem, pokud chce dosáhnout svého cíle zdravou cestou. Jinak bude vypadat menu pro 70kg ženu, která chce pouze vylepšit svůj jídelníček a udržovat váhu, a jinak pro 90kg muže, který chce nabrat svalovou hmotu nebo se naopak snaží zhubnout. Jídelníček je proto přizpůsoben na míru tělu a cílům, což obyčejná krabičková dieta nebere v potaz.",
  "src.components.organisms.Faq.delivery.subTitle6":
    "Do jakých měst rozvážíte krabičkovou dietu?",
  "src.components.organisms.Faq.delivery.content6":
    "Naši {info} rozvážíme po {prague}, {chech}, {kladno}, {plzen}, {liberec}, {mlda}, {jablonec}, {paradubic} a {hradec}.",
  "src.components.organisms.Faq.delivery.subTitle7":
    "Kdy doručujete jídla z krabičkové diety?",
  "src.components.organisms.Faq.delivery.content7":
    "Jídla z naší {info} doručujeme obden – v úterý, čtvrtek a neděli, vždy mezi 16:00 a 22:00 hodinou.",
  "src.components.organisms.Faq.delivery.subTitle8":
    "V kolik mi jídlo doručíte?",
  "src.components.organisms.Faq.delivery.content8":
    "Čas doručení závisí na konkrétním místě a v den dovozu bude čas upřesněn s kurýrem.",
  "src.components.organisms.Faq.delivery.subTitle9":
    "Co když potřebuji změnit doručovací údaje pro krabičkovou dietu?",
  "src.components.organisms.Faq.delivery.content9":
    "Jsme flexibilní a rozumíme, že plány se mohou změnit. Čas či místo doručení naší {info} můžete změnit ještě v ten samý den do 12:00.",
  "src.components.organisms.Faq.consumption.subTitle1":
    "Musí se jídlo z krabičkové diety ohřívat?",
  "src.components.organisms.Faq.consumption.content1":
    "Některá jídla z naší {info} jsou určena k ohřevu, jiná ke konzumaci za studena. Pro lepší chuť však doporučujeme jídla ohřát. Informace o tom, které jídlo je třeba ohřát, zjistíte po načtení čárového kódu na krabičce.",
  "src.components.organisms.Faq.consumption.subTitle2":
    "Jak ohřívat jídlo z krabičkové diety?",
  "src.components.organisms.Faq.consumption.content2":
    "Pokud ohříváte jídla z naší {info} v mikrovlnné troubě, doporučujeme propíchnout dírky do folie. Čas ohřevu se liší podle druhu jídla a velikosti porce, ale doporučujeme použít střední výkon (600–700 W) pro rovnoměrné prohřátí.",
  "src.components.organisms.Faq.consumption.subTitle3":
    "Jak skladovat jídla z krabičkové diety?",
  "src.components.organisms.Faq.consumption.content3":
    "Jídla z naší krabičkové diety doporučujeme skladovat převážně v lednici, ale jsou samozřejmě přizpůsobeny na cesty nebo do práce, a vydrží tak i několik hodin mimo lednici bez vlivu na chuť či kvalitu.",
  "src.components.molecules.OnlineConfirmCard.price": "{price} Kč/den",
  "src.components.molecules.OnlineConfirmCard.jidel": "{jidel}сhodové menu",
  "src.components.atoms.ConfirmPrice.text": "Cena celkem (vč. DPH)",
  "src.components.atoms.ConfirmPrice.price": "{price} Kč",
  "src.components.organisms.PaymentModal.successTitle":
    "Děkujeme, vaši platbu jsme úspěšně přijali.",
  "src.components.organisms.PaymentModal.failTitle": "Platba nebyla dokončena.",
  "src.components.organisms.PaymentModal.successText":
    "Vaši objednávku {number} začínáme zpracovávat. Toto potvrzení jsme vám odeslali také na e-mail.",
  "src.components.organisms.PaymentModal.failText":
    "Je nám líto, ale něco se porouchalo a vaše platba nebyla dokončena.",
  "src.components.organisms.PaymentModal.succsesBtn": "Zpět na hlavní stránku",
  "src.components.organisms.PaymentModal.failBtn": "Zkusit znovu",
  "src.components.organisms.PaymentModal.back": "Zpět k výběru platby",
  "src.components.organisms.CookieModal.title":
    "Aby ti NutritionPro dobře fungoval ",
  "src.components.organisms.CookieModal.description":
    "Aby pro tebe bylo prohlížení našich stránek co nejpohodlnější, využíváme soubory cookies. Cookies nám slouží pro zlepšování našich služeb a zároveň ti díky nim dokážeme lépe nabídnout obsah, který pro tebe může být zajímavý a užitečný.",
  "src.components.organisms.CookieModal.link": "Více",
  "src.components.organisms.CookieModal.accept": "Přijmout vše",
  "src.components.organisms.CookieModal.reject": "Odmítnout",
  "error.phone.required": "Telefonní číslo musí obsahovat 9 znaků",
  "error.personal.required": "Políčko je vyžadováno.",
  "error.name.required": "Zadejte Vaše jméno a příjmení",
  "error.email.required": "Zadejte e-mail ve správném formátu",
  "error.paymentMethod.required": "Payment method je vyžadováno.",
  "error.date.required": "Date je povinný",
  "error.faktura.required": "Fakury je povinný",
  "error.companyName.required": "Zadejte název společnosti",
  "error.companyAddress.required": "Zadejte fakturační adresu",
  "error.vatNumber.required": "Zadejte IČO společnosti",
  "error.regNumber.required": "Zadejte DIČ společnosti",
  "error.polygon.required": "Město Vaší adresu",
  "error.pickUpPoint.required": "Vaší odberné místo",
  "error.addressCity.required": "Zadejte Vaší adresu",
  "error.zip.required": "Zadejte ZIP společnosti",
  "error.comment.required": "Pole je povinný",
  "src.components.routes.pages.ThankYouAffiliate.title": "Děkuji affiliate",
  "src.components.routes.pages.ThankYou.title": "Děkuji",
  "src.components.organisms.MenuBlock.titleFirst": "Jaká jídla",
  "src.components.organisms.MenuBlock.titleSecond": "tě s námi čekají?",
  "src.components.organisms.MenuBlock.tab5": "Pátek",
  "src.components.organisms.MenuBlock.tab4": "Čtvrtek",
  "src.components.organisms.MenuBlock.tab3": "Středa",
  "src.components.organisms.MenuBlock.tab2": "Úterý",
  "src.components.organisms.MenuBlock.tab1": "Pondělí",
  "src.components.organisms.MneuBlock.food1.title":
    "Kokosový francouzský toast s javorovým sirupem a hroznovým vínem",
  "src.components.organisms.MneuBlock.food2.title":
    "Ovocný salát s kešu oříšky",
  "src.components.organisms.MneuBlock.food3.title":
    "Roastbeef s medovo-hořčicovou omáčkou, bramborové měsíčky",
  "src.components.organisms.MneuBlock.food4.title":
    "Quiche se špenátovými listy a balkánským sýrem",
  "src.components.organisms.MneuBlock.food5.title":
    "Italské risoto s hlívou ústřičnou, bílým vínem a parmazánem",
  "src.components.organisms.MneuBlock.food6.title":
    "Ovesná kaše s kokosovým mlékem, borůvkami a tmavou čokoládou",
  "src.components.organisms.MneuBlock.food7.title":
    "Hruška s ořechy a čokoládou",
  "src.components.organisms.MneuBlock.food8.title":
    "Kuřecí šašlik, pečené bramborové plátky, BBQ omáčka",
  "src.components.organisms.MneuBlock.food9.title":
    "Rolky z krůtí šunky na salátových listech s kešu a parmazánem ",
  "src.components.organisms.MneuBlock.food10.title":
    "Tacos s mexickými fazolemi, strouhaným sýrem čedar,ledovým salátem a rajčaty",
  "src.components.organisms.MneuBlock.food11.title":
    "Řecký jogurt s lesním ovocem a medem",
  "src.components.organisms.MneuBlock.food12.title":
    "Staročeské makové palačinky se švestkovým rozvarem, tvarohem a strouhaným perníkem",
  "src.components.organisms.MneuBlock.food13.title":
    "Kuřecí steak Caprese, pečené bramborové plátky",
  "src.components.organisms.MneuBlock.food14.title":
    "Huevos rancheros s cizrnou",
  "src.components.organisms.MneuBlock.food15.title":
    "Losos s brokolicí, špenátem a rajčaty",
  "src.components.organisms.MneuBlock.food16.title":
    "Vajíčková pomazánka z cottage sýru a pažitky, tmavé pečivo",
  "src.components.organisms.MneuBlock.food17.title":
    "Tvarohový dezert s borůvkami",
  "src.components.organisms.MneuBlock.food18.title": "Lasagne Bolognese",
  "src.components.organisms.MneuBlock.food19.title":
    "Kokosové palačinky s krémovým sýrem a provensálskou zeleninou",
  "src.components.organisms.MneuBlock.food20.title":
    "Kuřecí medajlonky na tymiánovém bulguru s pečenými hranolkami mrkve a grilovanou cuketou",
  "src.components.organisms.MneuBlock.food21.title":
    "Pečená omeleta s čedarem a petrželkou",
  "src.components.organisms.MneuBlock.food22.title":
    "Raw ořechovo-datlové kuličky",
  "src.components.organisms.MneuBlock.food23.title":
    "Indické kuřecí Satay s burákovou omáčkou a rýží Basmati",
  "src.components.organisms.MneuBlock.food24.title":
    "Sendvič s kuřecím masem a krémovým sýrem",
  "src.components.organisms.MneuBlock.food25.title":
    "Vietnamská polévka Pho Bo",
  "src.components.organisms.MneuBlock.breakfast": "Snídaně",
  "src.components.organisms.MneuBlock.snack1": "Svačina 1",
  "src.components.organisms.MneuBlock.snack2": "Svačina 2",
  "src.components.organisms.MneuBlock.lunch": "Oběd",
  "src.components.organisms.MneuBlock.dinner": "Večeře",
  "src.component.organism.BannerBlock.title":
    "Objednej si krabičky s jídlem ještě dnes.",

  "src.components.organisms.ReviewBlock.title1": "Adam Pechar",
  "src.components.organisms.ReviewBlock.description1":
    "Jedna z nejlepších investic, kterou jsem za poslední dobu učinil, mohu jenom doporučit! Bezproblémová spolupráce, vyvážená strava a skvělá chuť. Nejlepší krabičková dieta, kterou jsem doposud vyzkoušel. Po jídle se cítím skvěle a pokaždé se těším na další chod. Zásadní krok pro zdravý životní styl. 👍 Jedním slovem PERFEKTNÍ",
  "src.components.organisms.ReviewBlock.title2": "Renata Vydrová",
  "src.components.organisms.ReviewBlock.description2":
    "Jídlo mají moc dobré. :) A navíc už nemusím řešit, co budu jaký den vařit. Za mě je to TOP!",
  "src.components.organisms.ReviewBlock.title3": "Anton Lancevič",
  "src.components.organisms.ReviewBlock.description3": "Mohu jen doporucit!",
  "src.components.organisms.ReviewBlock.title4": "Hana Císlerová",
  "src.components.organisms.ReviewBlock.description4":
    "Nejlepší krabičky, co jsem kdy zkoušela. Jídla bylo navíc dost, tak jsem se podělila i se synem a vyšlo nám to laciněji, než nakupovat a vařit",
  "src.components.organisms.ReviewBlock.title5": "Margita Novotná",
  "src.components.organisms.ReviewBlock.description5":
    "Porce víc než dostatečné, velmi rozmanité, chutné. Servis perfektní, domluva i další doporučení s ochotnou poradkyní bez chybičky. Doporučuji.",
  "src.components.organisms.ReviewBlock.title6": "Kristýna Burdová",
  "src.components.organisms.ReviewBlock.description6":
    "Krabičky jsou naprosto super, zachraňovaly mě během home officů, kdy jsem vůbec neměla čas si uvařit, natož něco zdravého. Salesačka Jitka mi pomohla naplánovat optimální kalorický objem tak, abych neměla hlad a zároveň trochu zhubla. Byla jsem moc mile překvapená, že se mě Jitka vyptala na moje stravovací a pohybové návyky a podle toho sestavila krabičky, které jsou velmi chutné. Doporučuju!",
  "src.components.organisms.ReviewBlock.title7": "Jan Vítek",
  "src.components.organisms.ReviewBlock.description7":
    "The fish with the veggies was amazing! 👌",
  "src.components.organisms.ReviewBlock.title8": "Jan Kříž",
  "src.components.organisms.ReviewBlock.description8":
    "Vždy výborná chuť i vzhled, přesně pro mě na míru, skvělý servis okolo, pro mě dokonalá služba a jsem konečně nakrmený tak, abych nemusel řešit dojídání kdekoli jinde. Navíc během covid stavu NutritionPro vyřešilo i situaci lidem kolem mě, kteří můžou zůstat a jíst doma, když se necítí bezpečně shánět a nakupovat cokoli venku. Takže palec hore a nejen pro letošní rok jasná volba. Díky za ní!!",
  "src.components.organisms.ReviewBlock.title9": "Lev Lyapeikov",
  "src.components.organisms.ReviewBlock.description9":
    "Great service! I have ordered twice for the stock. Couriers arrived on time, food quality is excellent. My wife at home with a 6 month old baby and there is no time for cooking. A diet of 2000 calories is enough for my wife all day and I take lunch with me for work. A very profitable solution, cheaper than buying products in the store + saving time. Today I made an order for 12 days (Pleasantly surprised by the low prices), for almost two weeks I’m free from shopping in groceries. Thank you so much for the yummy food and services!",
  "src.components.organisms.ReviewBlock.title10": "Nicole Ponce",
  "src.components.organisms.ReviewBlock.description10":
    "For someone that is a very picky eater, I would have to say that the meals from NutritionPro have exceeded my expectations and more. It makes my day so much easier to know that I have my meals prepared and ready everyday, straight to my door. I would highly recommend.",
  "src.components.organisms.ReviewBlock.title11": "Aneta Benova",
  "src.components.organisms.ReviewBlock.description11":
    "Velmi chutné a pestré jídlo :)",
  "src.components.organisms.ReviewBlock.title12": "Antoine Bourg",
  "src.components.organisms.ReviewBlock.description12":
    "Super satisfied, the recipes are simple yet diverse, and delightful. Feels like home cooking, and the results are seen very early on. Would recommend it to anyone looking for a tailored nutrition plan!!",
  "src.components.organisms.ReviewBlock.title13": "olina Moroz",
  "src.components.organisms.ReviewBlock.description13":
    "(Původní) Мне очень понравилось. Меню разнообразное и ,самое главное, вкусное. Советую! (Překlad) Opravdu se mi to líbilo. Nabídka je pestrá a hlavně chutná. Doporučuji!",
  "src.components.organisms.ReviewBlock.title14": "Ondřej Staněk",
  "src.components.organisms.ReviewBlock.description14":
    "Výborné krabičky. Moc se neopakují a často člověk ochutná i exotické potraviny, na které by normálně nenarazil. Porce skvělé a vyvážené. Hlad rozhodně nemám a přitom přirozeně pomaličku hubnu",
  "src.components.organisms.ReviewBlock.title15": "Tereza Žatečková",
  "src.components.organisms.ReviewBlock.description15":
    "Krabičky odebírám už delší dobu a stále si drží svou kvalitu. Jídla jsou pestrá, čerstvá a dovážka vždy přesná. Poměr kvalita/cena odpovídá. Doporučuji👍",
  "src.components.organisms.ReviewBlock.title16": "Viktor Nyitray",
  "src.components.organisms.ReviewBlock.description16":
    "S krabičkami jsem hodně spokojený. Nejsou to ty klasické jídla z vývařovny zabalené do krabiček, ale to jídlo je opravdu chutné! Doporučuji alespoň vyzkoušet :)",
  "src.components.organisms.ReviewBlock.title17": "Irena Rabochova",
  "src.components.organisms.ReviewBlock.description17":
    "Jídlo je velmi chutné a vyvážené, z čerstvých surovin. Úspora času a úbytek váhy. Servis je prvotřídní, zejména rozvoz.",
  "src.components.organisms.ReviewBlock.title18": "Lucie Bartošová",
  "src.components.organisms.ReviewBlock.description18":
    "Jídlo je naprosto skvělé, ale je tu ještě jedna věc o dost lepší, to jsou dovozci jídla, převážně jedna paní, vždy mi zlepší den, je naprosto dokonalá",
  "src.components.organisms.ReviewBlock.title19": "Tereza Hofmanová",
  "src.components.organisms.ReviewBlock.description19":
    "Velice dobré krabičky, jídla mi vyhovují svou pestrostí. Pro mě jasná doba v období, kdy nestíhám vařit. DOPORUČUJI!",
  "src.components.organisms.ReviewBlock.title20": "Zdenka Svobodova",
  "src.components.organisms.ReviewBlock.description20":
    "Moc chutné jídlo, velmi rozmanité na druhy surovin. A ochutnáte i nevšední jidlo. Kombinace mi někdy přišla zvláštní (čočka s rybou), ale vždy opravdu dobře připravené a i přes prvotní zvláštnost to ladí chuťově k sobě. Možnost si vybrat ze sladkých či slaných svačin. Mám sladkou ;) a plně uspokojí mlsouny co chtějí i nějaké kilčo shodit. Ze začátku jsem vnitřně bojovala s malými porcemi, ale to bylo mou předchozí rozežraností. Za 3 dny jsem si zvykla a po měsíci mohu zhodnotit, že porce jsou akorát, pestré a hlavně mi chutnají. Nemám vlastně pocit, že držím dietu ;) a na jídlo se těším. Rozvoz je také super, 3x týdně i v pozdních hodinách a paní co jezdí ke mně, je moc milá Děkuji a jen tak dál ;)",
  "src.components.organisms.ReviewBlock.title21": "Linda Dlabačová",
  "src.components.organisms.ReviewBlock.description21": "Nový jídelníček",
  "src.components.organisms.ReviewBlock.title22": "Maldonado David",
  "src.components.organisms.ReviewBlock.description22": "Super chutné jídlo",
  "src.components.organisms.ReviewBlock.title23": "Matěj Schultz",
  "src.components.organisms.ReviewBlock.description23":
    "Nejlepší poměr cena/kvalita, neskutečně jste mi pomohli, moc děkuji!!",
  "src.components.organisms.ReviewBlock.title24": "Klára Onderková",
  "src.components.organisms.ReviewBlock.description24":
    "Vždy dovoz včas a supr servis, díky za ušetřený čas nutrition pro!!",
  "src.components.organisms.ReviewBlock.title25": "Aneta pecková",
  "src.components.organisms.ReviewBlock.description25":
    "možná první podobný servis, který mi během prvního týdnu nezklamal, dokonce naopak, paráda!!",
  "src.components.organisms.ReviewBlock.title26": "Gregory Pelec",
  "src.components.organisms.ReviewBlock.title27":
    "Its been a while since I started my plan with you and surpisingly every meal was very delicious and the kilos went down finally!! Highly recommend The Home office pack to those who spend everyday in the office as I do",
  "src.components.organisms.ReviewBlock.title28": "Jitka Beranová",
  "src.components.organisms.ReviewBlock.description28":
    "Blíží se ke konci první měsíc co jsem s Vámi a už se citím tísickrát lépe, minus 3 kila a konečně mám čas a energii, jste naprosto Boží",
  "src.components.organisms.ReviewBlock.title29": "Jan Kříž",
  "src.components.organisms.ReviewBlock.description29":
    "Vždy výborná chuť i vzhled, přesně pro mě na míru, skvělý servis okolo, pro mě dokonalá služba a jsem konečně nakrmený tak, abych nemusel řešit dojídání kdekoli jinde. Navíc během covid stavu NutritionPro vyřešilo i situaci lidem kolem mě, kteří můžou zůstat a jíst doma, když se necítí bezpečně shánět a nakupovat venku. Takže palec hore a nejen pro letošní rok jasná volba. Díky za ní!!",
  "src.components.organisms.ReviewBlock.title30": "Eldar Alizade",
  "src.components.organisms.ReviewBlock.description30":
    "Jidlo chutna skvele, netrpim hlady, a hlavne jsem za mesic zhubnul o par kilo! Coz byl hlavni cil. Navic i konzultanti jsou ochotni a komunikace je vyborna. Takze vsechno super, doporucuji!",
  "src.components.organisms.ReviewBlock.title31": "Káčko Béčko",
  "src.components.organisms.ReviewBlock.description31":
    "Milé překvapení, obrovská spokojenost (můžu srovnávat i s konkurencí). Jídla dle mého gusta, objednala jsem znovu.",
  "src.components.organisms.ReviewBlock.title32": "Patrick Beznoska",
  "src.components.organisms.ReviewBlock.description32":
    "Skvělá služba a jídlo také",
  "src.components.organisms.ReviewBlock.title33": "Alan Pock",
  "src.components.organisms.ReviewBlock.description33":
    "To je pecka, po měsíci se mi to fakt líbí, asi nejvíce to, že nemusím řešit a pořád vymýšlet co si dám.",
  "src.components.organisms.ReviewBlock.title34": "Pavel Jiranek",
  "src.components.organisms.ReviewBlock.description34":
    "Tasty, quick and neat solution for your daily eating habits!",
  "src.components.organisms.ReviewBlock.title35": "Petra Dolanská",
  "src.components.organisms.ReviewBlock.description35":
    "Luxusni jidlo, super komunikace a porce akorát. Musím jenom DOPORUČIT",
  "src.components.organisms.ProgramBlock.meat.description1": "plně vyváženou",
  "src.components.organisms.ProgramBlock.meat.description2":
    "s bohatou paletou surovin",
  "src.components.organisms.ProgramBlock.meat.description3":
    "klade důraz na pestrost",
  "src.components.organisms.ProgramBlock.meat.description4":
    "minerálních látek a vitaminů",
  "src.components.organisms.ProgramBlock.meat.description5": "vlákniny.",
  "src.components.organisms.ProgramBlock.meat.description6": " 3-5 chodů",
  "src.components.organisms.ProgramBlock.meat.description7":
    "možnosti výměny pokrmů",
  "src.components.organisms.ProgramBlock.meat.description8":
    " zkusit některá z našich vegetariánských jídel",
  "src.components.organisms.ProgramBlock.meat.description9":
    "sezónní suroviny,",
  "src.components.organisms.ProgramBlock.meat.description10":
    "nejlepším recenzím",
  "src.components.organisms.ProgramBlock.meat.description11":
    "poznatky a výzkum v souladu s výživovými standardy",
  "src.components.organisms.ProgramBlock.meat.description12":
    "nutričních specialistů.",
  "src.components.organisms.ProgramBlock.meat.description13":
    "kalorické varianty,",
  "src.components.organisms.ProgramBlock.meat.description14":
    "ktuální tělesnou hmotnost, snížit tělesnou hmotnost ",
  "src.components.organisms.ProgramBlock.meat.description15":
    " nabrat svalovou hmotu.",
  "src.components.organisms.ProgramBlock.vege.description1":
    "nechtějí maso a ryby.",
  "src.components.organisms.ProgramBlock.vege.description2":
    "pestrou škálu surovin",
  "src.components.organisms.ProgramBlock.vege.description3":
    "dostatek bílkovin, vlákniny, minerálních látek a vitaminů.",
  "src.components.organisms.ProgramBlock.vege.description4": "3-5 chodů",
  "src.components.organisms.ProgramBlock.active.description1":
    "aktivní sportovce",
  "src.components.organisms.ProgramBlock.active.description2":
    "větší přísun energie a živin.",
  "src.components.organisms.ProgramBlock.active.description3":
    "bohatá na bílkoviny",
  "src.components.organisms.ProgramBlock.active.description4":
    "nižší množství tuku. ",
  "src.components.organisms.ProgramBlock.active.description5":
    "veškeré živiny v optimálním poměru",
  "src.components.organisms.ProgramBlock.fish.description1":
    "bez ryb a rybích produktů.",
  "src.components.organisms.ProgramBlock.fish.description2":
    "široký výběr chutných pokrmů, ",
  "src.components.organisms.ProgramBlock.fish.description3":
    "nutričně hodnotnou stravu.",
  "src.components.organisms.ProgramBlock.fish.description4": "3-5 chodů",
  "src.components.organisms.ProgramBlock.office.description1":
    "dvouchodové menu",
  "src.components.organisms.ProgramBlock.office.description2":
    "zvolit dle vlastních preferencí.",
  "src.components.organisms.WhyNutrition.item1.title":
    "Možnost výměny libovolného chodu",
  "src.components.organisms.WhyNutrition.item2.title":
    "Průměrné hodnocení našich jídel je 4,6/5",
  "src.components.organisms.WhyNutrition.item3.title": "Každý den nové menu",
  "src.components.organisms.WhyNutrition.item4.title":
    "Jídelníček na míru tvým cílům",
  "src.components.organisms.WhyNutrition.item5.title":
    "Nejpřesnější čas doručení",
  "src.components.organisms.WhyNutrition.item6.title":
    "Nejflexibilnější doprava",
  "src.components.organisms.WhyNutrition.item7.title":
    "Nutriční konzultace v ceně",
  "src.components.organisms.WhyNutrition.item8.title":
    "Nejprofesionálnější příprava jídel",
  "src.components.organisms.WhyNutrition.item1.description":
    "Každý z nás je jedinečný a ne všechno chutná všem. To u nás není problém. Můžete si libovolný chod zdarma zaměnit za jiný pokrm.",
  "src.components.organisms.WhyNutrition.item2.description":
    "Aktivně sbíráme zpětnou vazbu od klientů na každé jídlo a na základě ní, menu neustále vylepšujeme.",
  "src.components.organisms.WhyNutrition.item3.description":
    "Naše menu se skládá z více než 300 jídel. Po dobu 4 týdnů neuvidíte žádný pokrm 2x. Na své oblíbence se můžete těšit další měsíc.",
  "src.components.organisms.WhyNutrition.item4.description":
    "Díky našemu speciálnímu algoritmu můžeme nastavit jídelníček pro každého zákazníka na míru.",
  "src.components.organisms.WhyNutrition.item5.description":
    "Doručujeme v dvouhodinových časových rozmezích, které si můžeš libovolně měnit. Jídlo ti dovezeme až pod nos v čas, který si určíš.",
  "src.components.organisms.WhyNutrition.item6.description":
    "Nečekej celý večer doma na kurýra. Čas i místo doručení si můžeš flexibilně upravovat takřka do poslední chvíle. Jednoduše zavolej nebo napiš.",
  "src.components.organisms.WhyNutrition.item7.description":
    "Od prvního dne jsi na správné cestě. S tvými stravovacími cíli ti budou zdarma pomáhat naši výživoví konzultanti a nutriční expertka.",
  "src.components.organisms.WhyNutrition.item8.description":
    "Vaše jídla se připravují pod dohledem šéfkuchaře, nutričního specialisty a technologa výroby, což zaručuje nejvyšší kvalitu připravených pokrmů.",
  "src.components.organisms.WhyNutrition.redBtn": "Spočítej si cenu",
  "src.components.organisms.WhyNutrition.titleFirst": "Proč krabičková",
  "src.components.organisms.WhyNutrition.titleSecond": "dieta NutritionPro?",
  "src.components.organisms.CityDeliveryBlock.list.description1":
    "Dovážíme v zelené lokalitě.",
  "src.components.organisms.CityDeliveryBlock.list.description2":
    "Dovoz ve večerních hodinách mezi {clock}",
  "src.components.organisms.CityDeliveryBlock.list.descriptionPlzen":
    "Vybereš si jedno ze 3 časových oken pro doručení, už žádné celovečerní nahánění s kurýry!",
  "src.components.organisms.CityDeliveryBlock.list.description3":
    "Změnily se ti plány? Čas a místo si můžeš flexibilně změnit ještě v den dovozu do 10:00",
  "src.components.organisms.CityDeliveryBlock.list.title": "Jak to funguje?",
  "src.components.organisms.CityDeliveryBlock.title":
    "Nejpřesnější a nejflexibilnější doprava v",
  "src.components.routes.pages.DietSredniCechi.subTitle":
    "Nejpřesnější a nejflexibilnější doprava",
  "src.components.organisms.CallMeBlock.call": "Zavolejte mi",
  "src.components.organisms.CallMeBlock.order": "Objednejte online",
  "src.components.organisms.CallMeModal.title": "Zavolejte mi",
  "src.components.organisms.CallMeModal.programTitle": "Nezávazná konzultace",
  "src.components.organisms.CallMeModal.programDescription":
    "Chceš poradit s výběrem, nebo ti není něco jasné? Nech nám na sebe kontakt a my se ti ozveme.",
  "src.components.organisms.DesktopHeader.program": "Programy",
  "src.components.organisms.DesktopHeader.customerPortal": "Klientská zóna",
  "src.components.organisms.DesktopHeader.menu": "Jídelníček",
  "src.components.organisms.NotFoundBlock.title": "Stránka nenalezena",
  "src.components.organisms.NotFoundBlock.btn": "Zpět na domovskou stránku",
  "src.components.organisms.Delivery.brno.point": "1 odběrné místo",
  "src.components.organisms.Delivery.plzen.point": "1 odběrné místo",
  "src.components.organisms.Delivery.liberec.point": "1 odběrné místo",
  "src.components.organisms.Delivery.hradec.point": "1 odběrné místo",
  "src.components.organisms.Delivery.mlda.point": "1 odběrné místo",
  "src.components.organisms.Delivery.kladno.point": "1 odběrné místo",
  "src.components.organisms.Delivery.kladno.info":
    "Notifikace hodinového okna SMS",
  "src.components.routes.pages.Terms.description":
    "společnosti Nutritionpro s.r.o. se sídlem Inovační 122, 252 41 Zlatníky- Hodkovice, IČO 07752971",
  "src.components.routes.pages.Terms.content1.title": "I. Vymezení pojmů",
  "src.components.routes.pages.Terms.content2.title": "II. Objednávka",
  "src.components.routes.pages.Terms.content3.title":
    "III. Změna/storno objednávky",
  "src.components.routes.pages.Terms.content4.title": "IV. Dodání",
  "src.components.routes.pages.Terms.content5.title":
    "V. Vady programu NutritionPro a reklamace",
  "src.components.routes.pages.Terms.content6.title":
    "VI. Cena stravování programu NutritionPro",
  "src.components.routes.pages.Terms.content7.title": "VII. Závěrečná ujednání",
  "src.components.routes.pages.Terms.content1.description1":
    "Pro účely těchto Všeobecných obchodních podmínek mají níže uvedené termíny následující význam:",
  "src.components.routes.pages.Terms.content1.description2":
    "Společnost: Nutritionpro s.r.o. se sídlem Inovační 122, 252 41 Zlatníky- Hodkovice, IČO 07752971(dále jen „Provozovatel“)",
  "src.components.routes.pages.Terms.content1.description3":
    "E-mail: info@nutritionpro.cz",
  "src.components.routes.pages.Terms.content1.description3.tel":
    "Tel: +420 226 288 200",
  "src.components.routes.pages.Terms.content1.description4":
    "Program NutritionPro je programem pravidelného zdravého stravování, který zahrnuje přípravu a dodávání denních setů pokrmů podle klientem vybraného a objednaného počtu denních/týdenních/měsíčních cyklů Provozovatelem, a to na místo určené klientem nebo na odběrné místo. (dále jen „Program NutritionPro“)",
  "src.components.routes.pages.Terms.content1.description5":
    "Denní set pokrmů je vyváženou kombinací stravy na jeden den dodávanou klientovi Provozovatelem. Denní set pokrmů bude individuálně přizpůsoben na optimální příjem makroživin podle údajů z úvodního dotazníku vyplněného klientem a vstupních údajů o složení těla. Pokrm v každém denním setu je přehledně uspořádán, označen, zabalen a takto jednou za dva dny dodáván klientovi na místo jím určeném nebo na odběrné místo.",
  "src.components.routes.pages.Terms.content1.description6":
    "Klientem je osoba objednávající Program NutritionPro, jakožto osoba mající zájem o nabízené služby spočívající v pravidelném stravování formou nutričně vyvážených a hodnotných potravin. Klient se zavazuje za tyto služby uhradit provozovateli sjednanou odměnu. (dále jen „Klient“)",
  "src.components.routes.pages.Terms.content2.description1":
    "1. Objednávku Programu NutritionPro lze provést následujícím způsobem:",
  "src.components.routes.pages.Terms.content2.description2":
    "2. Objednávku Programu NutritionPro je třeba uskutečnit nejméně 3 pracovní dny přede dnem, na který má být Program NutritionPro dodán. Každá učiněná objednávka je závazným návrhem na uzavření kupní smlouvy. Obsahem uzavřené kupní smlouvy (dále jen “Smlouva”) je povinnost Provozovatele dodávat Klientovi pokrmy, které si vybral během objednávky a povinnost Klienta uhradit mu za dodání těchto pokrmů kupní cenu. Smlouva je uzavřena na dobu určitou, přičemž délka této doby je Klientem vybrána při podání objednávky.",
  "src.components.routes.pages.Terms.content2.description3":
    "3. Pokud klient trpí alergií na některou z potravin, je povinen neprodleně telefonicky informovat provozovatele, přičemž bude současně dohodnut postup řešení takové situace. Klient je také povinen sdělit provozovateli jiné zdravotní obtíže, které mohou souviset s konzumací určitých pokrmů nebo surovin.",
  "src.components.routes.pages.Terms.content3.description1":
    "1. Klient je povinen sdělit Provozovateli změny objednávky, jako je zejména zrušení objednávky Programu NutritionPro či změna objednaného programu, ve lhůtě nejméně 3 pracovní dny přede dnem, na který má být Program NutritionPro dodán. Změnám, o kterých Klient informuje Provozovatele v kratší lhůtě, než je uvedeno v předchozí větě, nebude ze strany Provozovatele vyhověno, Program NutritionPro bude Klientovi řádně dodán a Klient je povinen uhradit Provozovateli cenu za takto dodaný Program NutritionPro v plné výši.",
  "src.components.routes.pages.Terms.content3.description2":
    "2. Pokud Klient změní/zruší objednávku v pracovní době zákaznické podpory, tj. Po-Pá od 10:00 - 12:00 a od 13:00 - 18:00 na telefonním čísle +420 226 288 200, nejpozději však 3 celé pracovní dny přede dnem doručení (nepočítá se však samotný den doručení, tj. den doručení minus 3 celé pracovní dny), Provozovatel provede změnu/odhlášku takto změněné/zrušené objednávky.",
  "src.components.routes.pages.Terms.content4.description1":
    "1. Provozovatel se zavazuje vyrobit Program NutritionPro dle objednávky Klienta a dopravit do místa určeného Klientem a tam odevzdat Klientovi způsobem uvedeným v odst. 2 tohoto článku.",
  "src.components.routes.pages.Terms.content4.description2":
    "2. Řidič rozvážející Program NutritionPro předá denní set pokrmů Klientovi, osobě Klientem určené nebo jiné osobě přítomné v místě určeném Klientem jako místo dodávky Programu NutritionPro. Okamžikem odevzdání Programu NutritionPro Klientovi přechází na Klienta nebezpečí škody na Programu. Pokud není na místě určeném Klientem jako místo dodání žádná osoba, které by mohl být Program NutritionPro předán a klient není telefonicky dostupný, bude Program NutritionPro uložen na skladu a Klient bude informován o možnostech vyzvednutí.",
  "src.components.routes.pages.Terms.content4.description3":
    "3. Denní set pokrmů je dodáván v chlazeném stavu a musí být až do jeho konzumace udržován v chladu, tedy v teplotě max. do 4 °C. Provozovatel nenese žádnou odpovědnost za jakost Programu NutritionPro, pokud po jeho odevzdání Klientovi dojde k porušení skladovacích podmínek.",
  "src.components.routes.pages.Terms.content4.description4":
    "4. Denní set pokrmů musí být spotřebován nejpozději do dne uvedeného na štítku. Po otevření je nutné pokrm ihned spotřebovat.",
  "src.components.routes.pages.Terms.content4.description5":
    "5. Denní set pokrmů bude na určené místo dodáván v časovém rozmezí od 6 do 10h nebo od 17 do 22 hodin, nebude-li pro konkrétní lokalitu Klientovi Provozovatelem sděleno jiné časové rozmezí. Klient si je vědom, že v mimořádných případech se může doba dodání změnit s ohledem na dopravní situaci a jiné obdobné události. O této změně bude klient informován telefonicky",
  "src.components.routes.pages.Terms.content4.description6":
    "6. V případě potřeby Klienta na změnu dohodnutého času dodání na místo určené klientem, je klient tuto změnu oprávněn provést prostřednictvím emailu nebo na telefonním čísle +420 226 288 200 do 12 hodin dne dodávky denního setu pokrmů.",
  "src.components.routes.pages.Terms.content5.description1":
    "1. Provozovatel není odpovědný za vady způsobené: neoprávněným zásahem, nevhodným skladováním, neodbornou manipulací, v důsledku použití zboží k jiným než určeným účelům, vlivem živelné pohromy nebo zásahem vyšší moci po předání zboží Klientovi.",
  "src.components.routes.pages.Terms.content5.description2":
    "2. Při převzetí zboží je kupující povinen zkontrolovat, zda dodaný pokrm nevykazuje vady způsobené přepravou. Pokud dodaný pokrm vykazuje vady, je kupující povinen dodaný pokrm nepřevzít a informovat o tom neprodleně prodávajícího prostřednictvím emailu na adrese: info@nutritionpro.cz nebo prostřednictvím formuláře {button}",
  "src.components.routes.pages.Terms.content5.description3":
    "3. Klient je oprávněn reklamovat pokrm pouze v případě, že z reklamovaného pokrmu nezkonzumoval / jinak neodstranil více jak 10 % z porce pokrmu. Jestliže reklamaci prodávající posoudí jako oprávněnou, bude vyřešena buďto výměnou za jiný pokrm ve stejné cenovéhladině, nebo ve formě tzv. Kreditů, které může Klient využít v další objednávce. Při reklamaci kvality pokrmů může Provozovatel požadovat svoz pokrmu řidičem určeným Provozovatelem.",
  "src.components.routes.pages.Terms.content5.description4":
    "4. Klient je povinen oprávněnost svého nároku, který uplatní prostřednictvím formuláře, nebo zasláním na uvedený e-mail, dle odst. 2 výše doložit také přiloženou fotografií, která dokládá namítanou vadu.",
  "src.components.routes.pages.Terms.content6.description1":
    "1. Klient se zavazuje objednaný Program NutritionPro v místě jím určeném od Provozovatele převzít a uhradit Provozovateli za tento Program řádně a včas Kupní cenu.",
  "src.components.routes.pages.Terms.content6.description2":
    "2. Provozovatel vyúčtuje Kupní cenu Klientovi fakturou s datem splatnosti do 5 dnů ode dne vystavení faktury. Provozovatel je oprávněn vystavit Klientovi fakturu v den, kdy si Klient Program NutritionPro objedná a zašle Klientovi fakturu e-mailem.",
  "src.components.routes.pages.Terms.content6.description3":
    "3. Klient uhradí Provozovateli Kupní cenu na základě vystavené faktury bezhotovostním převodem na bankovní účet Provozovatele pod variabilním symbolem určeným na faktuře.",
  "src.components.routes.pages.Terms.content6.description4":
    "4. Kupní cena se považuje za uhrazenou okamžikem jejího připsání na bankovní účet Provozovatele.",
  "src.components.routes.pages.Terms.content6.description5":
    "5. V případě prodlení s úhradou Kupní ceny může Provozovatel požadovat po Klientovi zaplacení úroku z prodlení.",
  "src.components.routes.pages.Terms.content6.description6":
    "6. V případě prodlení s úhradou Kupní ceny může Provozovatel odmítnout dodávku objednaného Programu až do úplné úhrady Kupní ceny za předchozí dodávky Programu NutritionPro",
  "src.components.routes.pages.Terms.content6.description7":
    "7. Provozovatel si vyhrazuje právo dodat Program NutritionPro až po uhrazení Kupní ceny Klientem.",
  "src.components.routes.pages.Terms.content7.description1":
    "1. Veškeré podmínky Smlouvou výslovně neupravené se řídí obecně závaznými právními předpisy České republiky, zejména ustanoveními zákona č. 89/2012 Sb., občanského zákoníku, v platném znění.",
  "src.components.routes.pages.Terms.content7.description2":
    "2. Tyto závazné smluvní podmínky jsou platné a účinné ode dne jejich zveřejnění na internetových stránkách provozovatele. Provozovatel je oprávněn tyto Všeobecné smluvní podmínky jednostranně měnit. O jakýchkoliv změnách nebo doplnění těchto závazných smluvních podmínek bude provozovatel klienty předem informovat. Pokud klient neprojeví se změnou nesouhlas do 30 dnů ode dne jejího oznámení, má se za to, že se změnou smluvních podmínek souhlasí. V případě, že Klient se změnou Všeobecných obchodních podmínek nesouhlasí, je oprávněn Smlouvu vypovědět s výpovědní dobou o délce 14 dní. V případě, ukáže-li se následně některé z ustanovení těchto smluvních podmínek jako neplatné či neúčinné, neznamená tato skutečnost neplatnost smluvních podmínek jako celku. Provozovatel se zavazuje takovéto neplatné či neúčinné ujednání nahradit jiným ujednáním a informovat o této změně klienta.",
  "src.components.routes.pages.Terms.content7.description3":
    "3. Akceptací těchto smluvních podmínek klientem se považují za závazně sjednané mezi ním a provozovatelem a klient nemá k obsahu Smlouvy a smluvních podmínek žádných výhrad.",
  "src.components.routes.pages.Terms.btn": "ODKAZ ZDE",
  "src.components.organisms.forms.TermsForm.btn": "Odeslat",
  "src.components.organisms.TermsModal.title": "Reklamační formulář",
  "src.components.organisms.forms.TermsForm.checkbox":
    "Souhlas se zpracováním osobních údajů pro marketingové účely.",
  "src.components.atoms.PricePerDay.dropava": "Cena za menu",
  "src.components.atoms.PricePerDay.dropavaDescription": "(bez dopravy)",
  "src.components.routes.pages.Gdpr.description1":
    "Společnost Nutritionpro s.r.o. se sídlem Inovační 122, 252 41 Zlatníky- Hodkovice, IČO 07752971, e-mail: info@nutritionpro.cz, tel: +420 777 510 839 (dále jen „Provozovatel“) ve smyslu nařízení Evropského parlamentu a Rady (EU) 2016/679 o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů (dále jen: „GDPR”) dbá zásad ochrany osobních údajů a zpracovává pouze ty osobní údaje, které slouží k poskytování služby vybrané zákazníkem a po dobu k tomuto účelu nezbytnou.",
  "src.components.routes.pages.Gdpr.description2":
    "Provozovatel zpracovává dále osobní údaje zástupců zákazníků (smluvních stran) a také potenciálních zákazníků, kteří mají zájem o služby Provozovatele. Účely zpracování osobních údajů jakož i informace o právních titulech, oprávněných zájmů a informace ohledně toho, že jsou osobní informace zpracovány pouze na základě svobodného a aktivního souhlasu klienta jsou vždy obsaženy v uzavřené smlouvě, nebo v obchodních podmínkách uveřejněných na webu Provozovatele. Ke zpracování osobních údajů dochází také za účelem plnění zákonných povinností Provozovatele vyvstávajících z daňového řádu, nebo jiného zákonného právního předpisu, i z jiných účelů, které sledují oprávněný zájem Provozovatele, tedy:",
  "src.components.routes.pages.Gdpr.description3":
    "1. evidence klientů, kteří byli kontaktováni obchodními zástupci Provozovatele v souvislosti s nabídkou na uzavření smlouvy, neb",
  "src.components.routes.pages.Gdpr.description4":
    "2. z důvodu předcházení podvodům, účely {info}",
  "src.components.routes.pages.Gdpr.description4.info":
    "kontroly nastavených technologických procesů",
  "src.components.routes.pages.Gdpr.description5":
    "3. náboru nových zaměstnanců, kteří v rámci přijímací pohovoru sdělí Provozovatele své osobní údaje. Údaje neúspěšných uchazečů jsou uchovány maximálně po dobu 6 měsíců za účelem jejich případného užití spočívající v oslovení s jinou nabídkou zaměstnání/ účasti v jiném nebo opakovaném výběrovém řízení na obsazení pracovní pozice, nedohodnou-li se strany jinak,",
  "src.components.routes.pages.Gdpr.description6":
    "4. přímého marketingu, kterým se rozumí zpracování osobních údajů stávajících zákazníků za účelem přímého oslovení obchodní nabídkou nebo výzkumem. Provozovatel upozorňuje, že pokud zákazník podá u konkrétní služby námitku proti přímému marketingu, Provozovatel již nadále nebude nijak zpracovávat osobní údaje zákazníka za tímto účelem, stejně tak jako přestane zákazníka oslovovat/zasílat obchodní nabídky,",
  "src.components.routes.pages.Gdpr.description7":
    "5. výkon a obhajoba nároků Provozovatele, případně",
  "src.components.routes.pages.Gdpr.description8":
    "z důvodu výkonu a prosazování právních nároků v rámci reklamací,soudních sporů a výkonů rozhodnutí.",
  "src.components.routes.pages.Gdpr.description9":
    "Informace obsažené v tomto dokumentu doplňují informace, které jsou zákazníkům poskytnuty v uzavírané smlouvě.",
  "src.components.routes.pages.Gdpr.description10":
    "Totožnost a kontaktní údaje správce",
  "src.components.routes.pages.Gdpr.description11":
    "Společnost Nutritionpro s.r.o. se sídlem Inovační 122, 252 41 Zlatníky- Hodkovice, IČO 07752971, e-mail: info@nutritionpro.cz, tel: +420 777 510 839",
  "src.components.routes.pages.Gdpr.description12":
    "Kontaktní údaje Pověřence pro ochranu osobních údajů",
  "src.components.routes.pages.Gdpr.description13":
    "Ve všech záležitostech souvisejících se zpracováním vašich osobních údajů je vám k dispozici náš pověřenec pro ochranu osobních údajů.",
  "src.components.routes.pages.Gdpr.description14":
    "Pověřence lze kontaktovat emailem na adrese info@nutritionpro.cz",
  "src.components.routes.pages.Gdpr.description15":
    "Poučení o právu podat námitky",
  "src.components.routes.pages.Gdpr.description16":
    "Ve všech případech zpracovávání osobních informací ze strany Provozovatele má klient právo podat proti takovému zpracování námitku. Námitka je posléze posouzena, a pokud pro to existují náležité zákonné důvody, bude ji vyhověno. ",
  "src.components.routes.pages.Gdpr.description17": "Zabezpečení",
  "src.components.routes.pages.Gdpr.description18":
    "Všechny zpracovávané osobní údaje jsou zabezpečeny náležitými organizačně technických opatřeními. ",
  "src.components.routes.pages.Gdpr.description19": "Příjemci osobních údajů",
  "src.components.routes.pages.Gdpr.description20":
    "Příjemci osobních údajů je fyzická nebo právnická osoba, orgán veřejné moci, agentura nebo jiný subjekt, kterým jsou osobní údaje poskytnuty, ať už se jedná o třetí stranu, či nikoli. Orgány veřejné moci, které mohou získávat osobní údaje v rámci zvláštního šetření v souladu s právem členského státu, se za příjemce nepovažují; zpracování těchto osobních údajů těmito orgány veřejné moci musí být v souladu s použitelnými pravidly ochrany údajů pro dané účely zpracování. ",
  "src.components.routes.pages.Gdpr.description21":
    "Provozovatel předává osobní údaje pouze následujícím příjemcům, kteří se podílejí se na dodání zboží / služeb / realizaci plateb na základě smlouvy, zajišťující marketingové služby.",
  "src.components.routes.pages.Gdpr.description22":
    "Správce nemá v úmyslu předat osobní údaje do třetí země (do země mimo EU) nebo mezinárodní organizaci.",
  "src.components.routes.pages.Gdpr.description23":
    "Základní doba, po kterou jsou osobní údaje uloženy, je vždy stanovená v uzavírané smlouvě mezi klientem a Provozovatelem. ",
  "src.components.routes.pages.Gdpr.description24":
    "Základní doba, po kterou jsou osobní údaje uloženy, je vždy stanovená v uzavírané smlouvě mezi klientem a Provozovatelem.",
  "src.components.routes.pages.Gdpr.description25":
    "Osobní údaje potenciálních klientů, kteří předali své osobní údaje, ale smlouvu neuzavřeli, jsou osobní údaje uchovávány po dobu 1 roku, a to pro evidenční účely. ",
  "src.components.routes.pages.Gdpr.description26":
    "Pro evidenční účely na dobu 10 let jsou uchovávány osobní údaje klientů od okamžiku ukončení účinnosti smlouvy.",
  "src.components.routes.pages.Gdpr.description27": "Ukládané údaje",
  "src.components.routes.pages.Gdpr.description28.info": "Kontaktní údaje:",
  "src.components.routes.pages.Gdpr.description28":
    "{info} Jméno, adresa, telefonní číslo, e-mailová adresa, ičo a to z toho důvodu, že zpracování je nezbytné pro splnění smlouvy, jejíž smluvní stranou je subjekt údajů, nebo pro provedení opatření přijatých před uzavřením smlouvy na žádost tohoto subjektu údajů, případně je zpracování nezbytné pro účely oprávněných zájmů příslušného správce či třetí strany, kromě případů, kdy před těmito zájmy mají přednost zájmy nebo základní práva a svobody subjektu údajů vyžadující ochranu osobních údajů. Účelem tak je, aby Provozovatel věděl, co po něm Klient požaduje a z jakého důvodu ho kontaktoval.",
  "src.components.routes.pages.Gdpr.description29.info1": "Lokalizační údaje:",
  "src.components.routes.pages.Gdpr.description29.info2":
    "Co do důvodů a účelu platí obdobně to, co je sděleno výše u kontaktních údajů, zejm. však pro účely doručování.",
  "src.components.routes.pages.Gdpr.description29":
    "{info1} Adresa, PSČ, město, stát. {info2}",
  "src.components.routes.pages.Gdpr.description30.info1": "Komunikační údaje:",
  "src.components.routes.pages.Gdpr.description30.info2":
    "Co do důvodů a účelu platí obdobně to, co je sděleno výše u kontaktních údajů, zejm. však z důvodu mohl, aby mohl Provozovatel Klienta kontaktovat za účelem plnění dle smlouvy, s náležitým a uctivým oslovením, případně z důvodu řešení komplikací spojených s doručováním, nebo řešením reklamací.",
  "src.components.routes.pages.Gdpr.description30":
    "{info1} Jméno, e-mailová adresa, telefonní číslo. {info2}",
  "src.components.routes.pages.Gdpr.description31.info1": "Platební údaje:",
  "src.components.routes.pages.Gdpr.description31.info2":
    "Co do důvodů a účelu platí obdobně to, co je sděleno výše u kontaktních údajů, zejm. však, aby mohl Provozovatel sledovat platby a přiřadit je k řešeným objednávkám, případně k řešení reklamací.",
  "src.components.routes.pages.Gdpr.description31":
    "{info1} způsob platby. {info2}",
  "src.components.routes.pages.Gdpr.description32.info1": "Dodací údaje:",
  "src.components.routes.pages.Gdpr.description32.info2":
    "Co do důvodů a účelu platí obdobně to, co je sděleno výše u kontaktních údajů, zejm. však dodání objednávek. Našim řidičům jsou předávány pouze nezbytné údaje pro doručení.",
  "src.components.routes.pages.Gdpr.description32":
    "{info1} Jméno, dodací adresa, telefonní číslo, identifikační číslo zákazníka. {info2}",
  "src.components.routes.pages.Gdpr.description33": "Další ukládané údaje",
  "src.components.routes.pages.Gdpr.description34":
    "Uchovávány jsou také veškeré další informace, osobní údaje, které klienti předají o jiných fyzických osobách, se kterými spolupracuje nebo jejichž údaje jsou pro plnění smluvního vztahu nezbytné. Tyto osoby je klient povinen obdobným způsobem informovat o předání jejich osobních údajů.",
  "src.components.routes.pages.Gdpr.description35":
    "Stejně tak Provozovatel shromažďuje osobní údaje, které získá v průběhu telefonních hovorů, případně také na sociálních sítích (facebook, twitter, apod.). Telefonní hovor může být monitorován a nahráván za účelem zajištění kvality a řádného fungování našich služeb. Záznamy mohou být uchovány až šest měsíců pouze pro interní potřeby. Co do důvodů a účelu platí obdobně to, co je sděleno výše u kontaktních údajů.",
  "src.components.routes.pages.Gdpr.description36":
    "Konkrétní účely zpracovávání údajů",
  "src.components.routes.pages.Gdpr.description37.info": "Řidiči/doručovatelé:",
  "src.components.routes.pages.Gdpr.description37":
    "{info} Těmto osobám, ať již jsou v zaměstnaneckém, nebo obdobném poměru, nebo se jedná o třetí stranu, která je pověřena doručením objednávky jsou předávány údaje nezbytné pro doručení objednávky. Kategorií osobních údajů jsou dodací údaje.Ve smyslu § 6 odst., písm. b), GDPR.",
  "src.components.routes.pages.Gdpr.description38.info1": "Přímý marketing:",
  "src.components.routes.pages.Gdpr.description38.info2":
    "Kategorií osobních údajů jsou",
  "src.components.routes.pages.Gdpr.description38":
    "{info1}  Sdělením vaší emailové adresy souhlasíte sezasíláním našich obchodních nabídek a to prostřednictvím emailu. Zpracování osobních údajů (telefonních čísel, e-mailových adres apod.) zákazníků pro účely přímého marketingu (při rozesílce obchodních sdělení) je zpracování prováděné z důvodu oprávněného zájmu dle čl. 6 odst. 1 písm. f) GDPR. Informujeme tímto naše klienty o jejich právu vznést námitku dle čl. 21 odst. 2 GDPR proti zpracování osobních údajů pro účely přímého marketingu. Pokud Klient tuto námitku vznese, nesmějí již být osobní údaje pro tento účel zpracovány (jde tedy o tzv. princip opt-out), viz níže, odvolání svého souhlasu. {info2} kontaktní údaje a lokalizační údaje. V případě, že klient nesouhlasí od počátku se zasíláním obchodních sdělení, emailů, apod. výše, v rámci přímého marketingu, ať Provozovateli sdělí tento svůj požadavek v rámci své první objednávky, případně ať předem zašle toto sdělení na níže uvedenou emailovou adresu, viz. odvolání svého souhlasu.",
  "src.components.routes.pages.Gdpr.description39.info": "Cílení (targeting).",
  "src.components.routes.pages.Gdpr.description39":
    "{info}  Cílením se má přizpůsobování, tj. vypínání, aktivace a modulace reklamních bannerů na webových stránkách určitým cílovým skupinám za účelem co nejefektivnějším sdělení uživateli webu/ potenciálnímu zákazníkovi na individualizované bázi.",
  "src.components.routes.pages.Gdpr.description40":
    "Retargetting. Retargettingem se rozumí, pokud využití souborů cookie v případě opětovného navštívení webových stránek Provozovatele. Tato funkce může být deaktivována také deaktivací doplňků ve svém webovém prohlížeči, případně omezena až deaktivována mazáním souborů cookie. Kategorií osobních údajů jsou kontaktní údaje.",
  "src.components.routes.pages.Gdpr.description41":
    "Cookies. Cookie je krátký textový soubor, který si navštívený web ukládá ve vašem prohlížeči. Umožňuje webu zaznamenat informace o vaší návštěvě.Příští návštěva tak pro vás může být snazší a web bude užitečnější. Většina prohlížečů umožňuje ovládat způsob nastavení a používání souborů cookie při prohlížení a umožňuje smazat soubory cookie i údaje o prohlížení. Váš prohlížeč navíc možná nabízí správu souborů cookie na jednotlivých webech. K užívání cookies z naší strany můžete vyjádřit svůj nesouhlas a to patřičným sdělením na níže uvedený email, viz. Odvolání svého souhlasu. Vaši žádost vyřešíme bez zbytečného odkladu , ovšem Provozovatel upozorňuje na to, že tento nesouhlas bude mít svůj účinek pouze v rámci webového prohlížeče, ze kterého svůj nesouhlas vytvoříte.",
  "src.components.routes.pages.Gdpr.description42.info":
    "Odvolání svého souhlasu",
  "src.components.routes.pages.Gdpr.description42":
    "{info} docílíte sdělením, že odvoláváte svůj souhlas se užitím osobních údajů na adresu info@nutritionpro.cz. Na tuto adresu posílejte také své případné NÁMITKY proti zpracování osobních údajů.",
  "src.components.routes.pages.Gdpr.description43": "Vaše práva",
  "src.components.routes.pages.Gdpr.description44":
    "1. Za podmínek stanovených v GDPR máteDále máte právo podat stížnost u Úřadu pro ochranu osobních údajů v případě, že se domníváte, že bylo porušeno Vaší právo na ochranu osobních údajů.",
  "src.components.routes.pages.Gdpr.description45":
    "2. Dále máte právo podat stížnost u Úřadu pro ochranu osobních údajů v případě, že se domníváte, že bylo porušeno Vaší právo na ochranu osobních údajů.",
  "src.components.routes.pages.Gdpr.description46": "Závěrečná ustanovení",
  "src.components.routes.pages.Gdpr.description47":
    "1.Odesláním objednávky z internetového objednávkového formuláře potvrzujete, že jste seznámen/a s podmínkami ochrany osobních údajů a že je v celém rozsahu přijímáte.",
  "src.components.routes.pages.Gdpr.description48":
    "2. S těmito podmínkami souhlasíte zaškrtnutím souhlasu prostřednictvím internetového formuláře. Zaškrtnutím souhlasu potvrzujete, že jste seznámen/a s podmínkami ochrany osobních údajů a že je v celém rozsahu přijímáte.",
  "src.components.routes.pages.Gdpr.description49":
    "Správce je oprávněn tyto podmínky změnit. Novou verzi podmínek ochrany osobních údajů zveřejní na svých internetových stránkách, případně Vám zašle novou verzi těchto podmínek na e-mailovou adresu, kterou jste správci poskytl/a.",
  "src.components.routes.pages.Gdpr.description50":
    "Vyhrazujeme si právo toto prohlášení o ochraně osobních údajů změnit v souladu s právními ředpisy. O případných významných změnách, jako jsou změny účelu nebo nové účely zpracování, vás budeme informovat.",
  "src.components.organisms.forms.TermsForm.name": "Jméno a příjmení",
  "src.components.organisms.forms.TermsForm.email": "E-mail",
  "src.components.organisms.forms.TermsForm.fakutra": "Číslo faktury",
  "src.components.organisms.forms.TermsForm.comment": "Příčina reklamace",
  "src.components.organisms.forms.TermsForm.comment2":
    "Váš požadavek na řešení",
  "src.components.routes.pages.VyzkousejMenu.title": "VyzkousejMenu",
  "src.components.molecules.DeliveryCard.btn": "Ukázat více",
  "src.components.atoms.KalorieItem.kalorie": "kcal",
  "src.components.organisms.OrderOnlineFirstStep.people":
    "Objednávám jídlo pro",
  "src.components.organisms.OrderOnlineFirstStep.menu": "Vyberte si menu",
  "src.components.organisms.OrderOnlineFirstStep.program": "Jaký máte cíl?",
  "src.components.organisms.OrderOnlineFirstStep.duration": "Délka programu",
  "src.components.organisms.OrderOnlineFirstStep.duration.description":
    "Jak dlouho chcete jídlo dovážet?",
  "src.components.organisms.OrderOnlineFirstStep.dayWeek": "Délka týdne",
  "src.components.organisms.OrderOnlineFirstStep.dayWeek.description":
    "Kolik dní v týdnu budeme dovážet?",
  "src.components.molecules.OnlineProgramBlock.lose.title": "Chci zhubnout",
  "src.components.molecules.OnlineProgramBlock.gain.title": "Chci nabrat svaly",
  "src.components.molecules.OnlineProgramBlock.maintanace.title":
    "Chci si udržet svou váhu",
  "src.components.molecules.OnlineProgramBlock.lunch.title":
    "Nechci doma vařit",
  "src.components.molecules.ProgramWeekDuration.dni": " {day} dní",
  "src.components.molecules.ProgramWeekDuration.info6": "(pondělí až sobota)",
  "src.components.molecules.ProgramWeekDuration.info5": "(pondělí až pátek)",
  "src.components.molecules.ProgramWeekDuration.info4": "(pondělí až čtvrtek)",
  "src.components.molecules.ProgramWeekDuration.info6.mob": "pondělí až sobota",
  "src.components.molecules.ProgramWeekDuration.info5.mob": "pondělí až pátek",
  "src.components.molecules.ProgramWeekDuration.info4.mob":
    "pondělí až čtvrtek",

  "src.components.molecules.OnlinePersonCard.sebe": "Sebe",
  "src.components.molecules.OnlinePersonCard.people": "2 osoby",
  "src.components.molecules.OnlinePersonCard.lidi": "4+ lidí",
  "src.components.molecules.OnlinePersonCard.tooltip":
    "Pro objednávku pro více osob použijte formulář 'Mám zájem'",
  "src.components.molecules.OnlinePersonCard.info":
    "Při objednání 2-4 jidelničků na stejnou adresu se platí pouze jedna doprava.",
  "src.components.molecules.OrderRightCard.call": "Mám zájem",
  "src.components.molecules.OrderRightCard.nebo": "nebo",
  "src.components.templates.OnlineOrderMobileTemplate.close": "Zrušit",
  "src.components.organisms.PromoCodeModal.title": "Promokód",
  "src.components.organisms.PromoCodeModal.field": "Vložit promokód",
  "src.components.organisms.forms.PromoCodeForm.btn": "Uplatnit",
  "src.components.organisms.forms.PromoCodeForm.errorMessage":
    "Tento promo kód není platný",
  "src.components.molecules.OrderRightCard.call.title": "Mám zájem",
  "src.components.organisms.OnlineOrderSecondStep.info": "Osobní informace",
  "src.components.organisms.OnlineOrderSecondStep.name": "Jméno a příjmení",
  "src.components.organisms.OnlineOrderSecondStep.email": "E-mail",
  "src.components.organisms.OnlineOrderSecondStep.psc": "PSČ",
  "src.components.organisms.OnlineOrderSecondStep.ico": "IČO ",
  "src.components.organisms.OnlineOrderSecondStep.dic": "DIČ",
  "src.components.organisms.OnlineOrderSecondStep.factura": "Fakturační adresa",
  "src.components.organisms.OnlineOrderSecondStep.companyName":
    "Název společnosti",
  "src.components.organisms.OnlineOrderSecondStep.switchCompany":
    "Objednat na firmu",
  "src.components.organisms.OnlineOrderSecondStep.companyInfo":
    "Fakturační údaje",
  "src.components.molecules.OrderSecondStepRightCard.title": "Cena objednávky",
  "src.components.molecules.OnlineOrderAddressCheck.city": "Město",
  "src.components.molecules.OnlineOrderAddressCheck.pickUpPoint":
    "Odberné místo",
  "src.components.molecules.OnlineOrderAddressCheck.address": "Adresa",
  "src.components.organisms.OnlineOrderThiredStep.calendar.title":
    "Začátek programu",
  "src.components.organisms.OnlineOrderThiredStep.calendar.description":
    "Zvolte datum, kdy chcete začít odebírat naše krabičky. První krabičky vám doručíme den před vybraným datem.",
  "src.components.organisms.OnlineOrderThiredStep.time.title":
    "Časové okno rozvozu",
  "src.components.organisms.OnlineOrderThiredStep.time.prague":
    "Vyber si nejvhodnější 2 hodinové okno rozvozu. Naši kurýři rozváží krabičky s jídlem od 18:00 do 22:00.",
  "src.components.organisms.OnlineOrderThiredStep.time.prague-area":
    "Vyber si nejvhodnější 2 hodinové okno rozvozu. Naši kurýři rozváží krabičky s jídlem od 18:00 do 22:00.",
  "src.components.organisms.OnlineOrderThiredStep.time.kladno":
    "Vyber si nejvhodnější 2 hodinové okno rozvozu. Naši kurýři rozváží krabičky s jídlem od 18:00 do 22:00.",
  "src.components.organisms.OnlineOrderThiredStep.time.plzen":
    "Vyber si nejvhodnější 2 hodinové okno rozvozu. Naši kurýři rozváží krabičky s jídlem od 17:00 do 21:00.",
  "src.components.organisms.OnlineOrderThiredStep.platba": "Zvolte platbu",
  "src.components.organisms.OnlineOrderThiredStep.platba.gopay":
    "Zabezpečenou platbu poskytuje",
  "src.components.molecules.PromoCodeApprove.promoCode": "Promokód ",
  "src.components.molecules.PromoCodeApprove.remove": "Odebrat",
  "src.components.molecules.OnlineOrderThiredStepSidebar.discount":
    "Množstevní sleva",
  "src.components.molecules.OnlineOrderThiredStepSidebar.confirm": "Objednat",
  "src.components.organisms.TimeSlotModal.title": "Časové okno rozvozu",
  "src.components.organisms.TimeSlotModal.field":
    "Vyberte si nejvhodnější 2 hodinové okno.",
  "src.components.organisms.TimeSlot.label": "Vyberte čas doručení",
  "src.components.organisms.CalendarOrder.label": "Vyberte datum",
  "src.components.organisms.CalendarModal.title": "Začátek programu",
  "src.components.organisms.CalendarModal.btn": "Potvrdit datum",
  "src.components.organisms.OrderOnlineFirstStep.porcel.description":
    "Kolik chodů ti budeme vozit",
  "src.components.organisms.OrderOnlineFirstStep.porcel": "Počet jídel denně",
  "src.components.organisms.HowCook.tab1.title": "Nejflexibilnější jídelníček",
  "src.components.organisms.HowCook.tab2.title": "Nejvyšší kvalita jídla",
  "src.components.organisms.HowCook.tab3.title": "Spolehlivě a vždy včas",
  "src.components.organisms.HowCook.tab1.title1":
    "Vybírej až ze 4 jídel na každý chod",

  "src.components.organisms.HowCook.tab1.descritpion1":
    "Máš chuť na sladkou, nebo slanou snídani? Dáš přednost lehkému salátu před teplou večeří? U nás je to na tobě!",
  "src.components.organisms.HowCook.tab1.title2":
    "Navol si jídelníček v klientské zóně",
  "src.components.organisms.RateBlock.subTitle":
    "10 jídel na 2 dny se slevou {greenTitle}",
  "src.components.organisms.BackBoxesRateBlock.subTitle1":
    "{boldTitle} na tvoji další objednávku!",
  "src.components.organisms.BackBoxesRateBlock.subTitleBold1": "Sleva 1500 Kč",
  "src.components.organisms.BackBoxesRateBlock.subTitle2":
    "Promokód: {boldTitle}",
  "src.components.molecules.OnlineOrderAddressCheck.tab1": "Doručení na adresu",
  "src.components.molecules.OnlineOrderAddressCheck.tab2":
    "Doručení na odběrné místo",
  "src.components.organisms.BackBoxesRateBlock.subTitleBold2": "ZPATKY1500",
  "src.components.organisms.HowCook.tab1.description2":
    "Vše si navolíš jednoduše v našem intuitivním uživatelském prostředí, kde zároveň vidíš i jak změna ovlivní rozložení makronutrientů.",
  "src.components.organisms.HowCook.tab1.title3": "Uzpůsob si příjem dle sebe",
  "src.components.organisms.HowCook.tab1.description3":
    "Kalorie si můžeš libovolně volit od 800-2600 kcal s přesností na 100 kcal a počet chodů od 2 do 5 s možností dokoupit extra porci.",
  "src.components.organisms.HowCook.tab1.title4":
    "Doplň si jídelníček o extra vitamíny",
  "src.components.organisms.HowCook.tab1.descripton4":
    "K jídlu si můžeš také dokoupit vitamínové balíčky v pohodlném jednodenním balení.",
  "src.components.organisms.HowCook.tab2.title1": "Dej přednost kvalitě",
  "src.components.organisms.HowCook.tab2.descritpion1":
    "Jídlo připravujeme v nejmodernějších kuchyních pod kontrolou našeho technologa a kvalitáře, dle nejvyšších hygienických standardů.",
  "src.components.organisms.HowCook.tab2.title2": "Užij si maximální čerstvost",
  "src.components.organisms.HowCook.tab2.description2":
    "Po celou dobu, od výroby po doručení, dbáme na optimální podmínky pro zachování kvality a čerstvosti jídla.",
  "src.components.organisms.HowCook.tab2.title3": "Dej svému tělu to nejlepší",
  "src.components.organisms.HowCook.tab2.description3":
    "Všechna naše jídla jsou kontrolována naší nutriční specialistkou. Máš tak jistotu, že kalorie pocházejí z těch nejkvalitnějších zdrojů, tvá jídla jsou vyvážená a bez použití umělých sladidel.",
  "src.components.organisms.HowCook.tab2.title4": "Vyzkoušej rozmanitost jídel",
  "src.components.organisms.HowCook.tab2.descripton4":
    "Nebaví tě jíst pořád stejná jídla? S našimi krabičkami tě jen tak něco neomrzí, tešit se můžeš na různorodá jídla - od lasagní po butter chicken. Samozřejmě pokud tě exotika neláká, máme pro tebe vždy alternativu.",
  "src.components.organisms.HowCook.tab3.title1": "Vyber si čas",
  "src.components.organisms.HowCook.tab3.descritpion1":
    "Jídlo vozíme ve 2hodinových oknech. Sám si podle svých možností zvolíš, kdy ti kurýr krabičky doručí.",
  "src.components.organisms.HowCook.tab3.title2":
    "Flexibilně si změň místo a čas",
  "src.components.organisms.HowCook.tab3.description2":
    "Místo i čas dovozu si ještě ten samý den můžeš změnit, případně si nechat dovézt na odběrné místo.",
  "src.components.organisms.HowCook.tab3.title3": "Využij naše odběrná místa",
  "src.components.organisms.HowCook.tab3.description3":
    "Jídlo si také můžeš vyzvednout na jednom z našich 16 odběrných míst, a to pohodlně dle tvých časových možností. Většinou nás najdeš ve fitness centrech a dalších frekventovaných místech.",
  "src.components.organisms.HowCook.tab3.title4":
    "Nech si jídlo přivézt až ke dveřím",
  "src.components.organisms.HowCook.tab3.descripton4":
    "Na změny jsme připraveni. Pokud tě čeká nečekaná pracovní cesta, adresu doručení můžeš pohodlně a flexibilně změnit a krabičky ti doručíme, kam bude potřeba.",
  "src.components.moelecules.BenefitLabels.text1":
    "Výběr ze {head} u každého chodu podle chuti.",
  "src.components.moelecules.BenefitLabels.subText1": "4 jídel ",
  "src.components.moelecules.BenefitLabels.text2":
    "Rozvážíme jídlo ve {head}, nebo na odběrná místa.",

  "src.components.moelecules.BenefitLabels.subText2": "2 hodinových oknech",
  "src.components.moelecules.BenefitLabels.text3":
    "Kalorie přes {head}, ne omáčky.",
  "src.components.moelecules.BenefitLabels.subText3": "bílkoviny",
  "src.components.moelecules.BenefitLabels.text4":
    "Pauza {head}, stačí zavolat.",
  "src.components.moelecules.BenefitLabels.subText4": "kdykoliv zdarma",
  "src.components.molecules.InfoHeader.text":
    "Odebíráš jinou krabičkovou dietu a zvažuješ změnu? Objednej teď na NutritionPro a získej voucher na 1000Kč do {info}",
  "src.components.molecules.InfoHeader.info": "Alza.cz",
  "src.components.atoms.HowCookItem.info": "Více info",
  "src.components.molecules.OnlineOrderSecondStepSidebar.terms1":
    "Pokračováním souhlasíte s",
  "src.components.molecules.OnlineOrderSecondStepSidebar.terms1.link":
    "obchodními podmínkami",
  "src.components.molecules.OnlineOrderSecondStepSidebar.terms2":
    "Jak nakládáme s vašimi osobními údaji, se dozvíte ",
  "src.components.molecules.OnlineOrderSecondStepSidebar.terms2.link": "zde.",
  "src.components.molecules.OnlineOrderThiredStepSidebar.priceDay":
    "Cena za menu (bez dopravy):",
  "src.components.molecules.OnlineOrderThiredStepSidebar.priceTotal":
    "Cena za celé období:",
  "src.components.molecules.OnlineOrderThiredStepSidebar.priceDelivery":
    "Cena dopravy za den:",
  "src.components.molecules.OnlineOrderThiredStepSidebar.priceDeliveryDemo":
    "Cena dopravy",
  "src.components.routes.pages.PaymentNotCompleted.title":
    "Payment not complite",
  "src.components.routes.pages.ThankYouOrder.title": "Order complited",
  "src.components.organisms.PaymentModal.succsesTitle":
    "Děkujeme, vaši objednávku jsme úspěšně přijali.",
  "src.components.organisms.PaymentModal.succsesText":
    "Mezitím jsme vám do e-mailu poslali veškeré informace k dokončení platby bankovním převodem.",
  "src.components.organisms.Faq.menu.content1.bold": "krabičková dieta",
  "src.components.organisms.Faq.menu.content2.bold": "krabičkovou dietu",
  "src.components.organisms.Faq.menu.content3.bold": "krabičková dieta",
  "src.components.organisms.Faq.menu.content3.boldInfo": "ukázku jídelníčku",
  "src.components.organisms.Faq.materials.content1.bold": "krabičkovou dietu",
  "src.components.organisms.Faq.materials.content2.bold": "krabičkové diety",
  "src.components.organisms.Faq.materials.content3.bold": "krabičkovou dietu",
  "src.components.organisms.Faq.materials.content4.bold": "krabičková dieta",
  "src.components.organisms.Faq.delivery.content1.bold": "krabičkové diety",
  "src.components.organisms.Faq.delivery.content2.bold": "krabičkové diety",
  "src.components.organisms.Faq.delivery.content3.bold": "krabičkové diety",
  "src.components.organisms.Faq.delivery.content3.boldInfo":
    "online kalkulačce",
  "src.components.organisms.Faq.delivery.content4.bold": "krabičkové diety",
  "src.components.organisms.Faq.delivery.content6.bold": "krabičkovou dietu",
  "src.components.organisms.Faq.delivery.content7.bold": "krabičkové diety",
  "src.components.organisms.Faq.delivery.content9.bold": "krabičkové diety",
  "src.components.organisms.Faq.consumption.content1.bold": "krabičkové diety",
  "src.components.organisms.Faq.consumption.content2.bold": "krabičkové diety",
  "src.components.organisms.Faq.consumption.content3.bold": "krabičkové diety",
  "src.components.organisms.Faq.client.subTitle1":
    "Mohu změnit své jídelníčky, pokud se změní mé potřeby?",
  "src.components.organisms.Faq.client.content1":
    "Ano, naše krabičková dieta je velmi flexibilní. Pokud potřebujete změnit své jídelníčky nebo upravit velikost porcí, můžete to snadno provést přes náš zákaznický portál nebo kontaktováním naší zákaznické podpory.",
  "src.components.organisms.Faq.client.subTitle2":
    "Jak mohu svůj jídelníček měnit?",
  "src.components.organisms.Faq.client.content2":
    "Menu vám můžeme přizpůsobit podle vašich preferencí, alergií a dalších požadavků dle vašich cílů. Krabičkovou dietu si zvolíte individuálně, od počtu chodů, přes vynechání neoblíbených ingrediencí, až po upravení velikosti porce.",
  "src.components.organisms.Faq.client.subTitle3":
    "Jak si dát pauzu v krabičkové dietě?",
  "src.components.organisms.Faq.client.content3":
    "Pauzu si můžete dát kdykoliv, nestojí to nic, stačí jen zavolat {info} nebo napsat e-mail na náš zákaznický servis info@nutritionpro.cz.",
  "src.components.organisms.Faq.client.content3.bold": "226 288 200",
  "src.components.organisms.Faq.client.subTitle4":
    "Jak mohu kontaktovat zákaznickou podporu, pokud mám problém nebo dotaz?",
  "src.components.organisms.Faq.client.content4":
    "Naše zákaznická podpora je vám k dispozici každý pracovní den od 08:00-18:00. Můžete nás kontaktovat telefonicky {info}, e-mailem info@nutritionpro.cz, nebo prostřednictvím našeho zákaznického portálu, kde se snažíme odpovědět co nejrychleji.",
  "src.components.organisms.Faq.client.content4.bold": "226 288 200",
  "src.components.organisms.Faq.client.subTitle5": "Co když mám problém?",
  "src.components.organisms.Faq.client.content5":
    "Vaše spokojenost je pro nás prioritou, proto budeme rádi za vaši zpětnou vazbu. Každou připomínku nebo stížnost s vámi řešíme individuálně a společně najdeme nejlepší možné řešení.",
  "src.components.organisms.Faq.delivery.prague": "celé Praze a okolí",
  "src.components.organisms.Faq.delivery.chech": "středních Čechách",
  "src.components.organisms.Faq.delivery.kladno": "Kladně",
  "src.components.organisms.Faq.delivery.plzen": "Plzni",
  "src.components.organisms.Faq.delivery.liberec": "Liberci",
  "src.components.organisms.Faq.delivery.mlda": "Mladé Boleslavi",
  "src.components.organisms.Faq.delivery.jablonec": "Jablonci nad Nisou",
  "src.components.organisms.Faq.delivery.paradubice": "Pardubicích",
  "src.components.organisms.Faq.delivery.hradec": "Hradci Králové",
  "src.components.organisms.OnlineOrderThiredStep.emptyTimeSlot":
    "Jídlo doručujeme od 17:00 do 22:00 v úterý, čtvrtek a neděli.",

  "src.components.organisms.OnlineOrderThiredStep.emptyTimeSlotHradec":
    "Jídlo doručujeme od 17:00 do 22:00 v úterý, čtvrtek a neděli.",
  "src.components.organisms.FaqPraha.menu.content1.bold":
    "krabičková dieta Praha",
  "src.components.organisms.FaqPraha.menu.content1.boldInfo":
    "krabičková dieta",
  "src.components.organisms.FaqPraha.menu.content1":
    "Naše {info} se vyznačuje individuálním přístupem, protože věříme, že každý člověk je jedinečný a potřebuje specifický kalorický příjem, aby dosáhl svých cílů zdravou cestou. Menu pro 70kg ženu, která chce zhubnout, bude vypadat úplně jinak než pro 90kg muže, který se snaží nabrat svalovou hmotu. Naše {boldInfo} je navržena tak, aby odpovídala vašim individuálním potřebám a cílům, což je něco, co běžné krabičkové diety často opomíjejí.",
  "src.components.organisms.FaqPraha.menu.content2":
    "Jídelníček pro naši {info} je sestavován speciálním algoritmem, který pečlivě zohledňuje potřeby vašeho těla a vaše cíle. Naše odbornice na výživu, Jitka Dokoupilová, dohlíží na složení jednotlivých jídel a zajišťuje, že dosáhnete požadovaných výsledků.",
  "src.components.organisms.FaqPraha.menu.content2.bold":
    "krabičkovou dietu Praha",
  "src.components.organisms.FaqPraha.menu.content3.boldInfo":
    "krabičkovou dietu Praha.",
  "src.components.organisms.FaqPraha.menu.content3.bold": "krabičková dieta",
  "src.components.organisms.FaqPraha.menu.content3":
    "Naše {info} je založena na zásadách vyvážené stravy. Jídelníček je pestrý a chutný, s více než 500 různými jídly, která se pravidelně obměňují. Neustále aktualizujeme naši databázi jídel, abychom vám mohli nabídnout nové a zajímavé možnosti, takže se naše jídla nikdy nepřejí. Podívejte se na ukázku jídelníčku, který nabízíme pro {boldInfo}",
  "src.components.organisms.FaqPraha.materials.content1":
    "Pro naši {info} používáme pouze kvalitní a čerstvé suroviny od prověřených českých dodavatelů. Kvalita a čerstvost surovin je pro nás základem zdravého stravování.",
  "src.components.organisms.FaqPraha.materials.content1.bold":
    "krabičkovou dietu Praha",
  "src.components.organisms.FaqPraha.materials.content2":
    "Ano, všechna jídla v rámci naší {info} jsou připravována z čerstvých surovin, které kupujeme v den přípravy. Po uvaření jsou jídla šokově zchlazena a hermeticky zabalena, což zajišťuje jejich maximální čerstvost.",
  "src.components.organisms.FaqPraha.materials.content2.bold":
    "krabičkové diety Praha",
  "src.components.organisms.FaqPraha.materials.content3":
    "Příprava jídel pro naši {info} probíhá v den rozvozu. Jídla připravujeme v neděli na pondělí a úterý, v úterý na středu a čtvrtek, a ve čtvrtek na pátek a sobotu.",
  "src.components.organisms.FaqPraha.materials.content3.bold":
    "krabičkovou dietu",
  "src.components.organisms.FaqPraha.materials.content4":
    "Ano, naše {info} zahrnuje i výborné dezerty, které jsou přesně spočítány tak, aby zapadly do vašeho osobního jídelníčku.",
  "src.components.organisms.FaqPraha.materials.content4.bold":
    "krabičková dieta Praha",
  "src.components.organisms.FaqPraha.delivery.content1":
    "Jakékoliv změny objednávky v rámci naší {info}, včetně pozastavení dovozu, je nutné nahlásit nejpozději do 11:00 tři pracovní dny před plánovaným doručením. Tato pravidla platí, protože pro každé vaření nakupujeme čerstvé suroviny na přesný počet zákazníků.",
  "src.components.organisms.FaqPraha.delivery.content1.bold":
    "krabičkové diety Praha",
  "src.components.organisms.FaqPraha.delivery.content2.bold":
    "krabičkové diety Praha",
  "src.components.organisms.FaqPraha.delivery.content2":
    "Pokud se vaše objednávka {info} vztahuje na dva dny, je možné zrušit pouze celou dvoudenní objednávku. Zrušení pouze jednoho dne není možné.",
  "src.components.organisms.FaqPraha.delivery.content3.bold":
    "krabičkové diety Praha",
  "src.components.organisms.FaqPraha.delivery.content3":
    "Cena naší {info} je stanovena individuálně podle vašeho osobního stravovacího plánu a denního kalorického příjmu. Cena se může lišit v závislosti na vašich cílech, ať už se jedná o hubnutí, nabírání svalové hmoty nebo udržování váhy. Kalkulaci ceny si můžete nechat vypočítat v naší online kalkulačce.",
  "src.components.organisms.FaqPraha.delivery.content4":
    "Po dokončení objednávky naší {info} vám zašleme fakturu na váš e-mail. Můžete také zvolit platbu kartou.",
  "src.components.organisms.FaqPraha.delivery.content4.bold":
    "krabičkové diety Praha",
  "src.components.organisms.FaqPraha.delivery.content6":
    "Naši {info} rozvážíme po {prague}, {chech} , {kladno}, {plzen}, {liberec}, {mlda}, {jablonec}, {paradubic} a {hradec}.",
  "src.components.organisms.FaqPraha.delivery.content6.bold":
    "krabičkovou dietu Praha",
  "src.components.organisms.FaqPraha.delivery.content7":
    "Jídla z naší {info} doručujeme obden – v úterý, čtvrtek a neděli, vždy mezi 16:00 a 22:00 hodinou.",
  "src.components.organisms.FaqPraha.delivery.content7.bold":
    "krabičkové diety Praha",
  "src.components.organisms.FaqPraha.delivery.content8":
    "Můžete si vybrat konkrétní 2hodinové okno pro doručení (17:00 - 22:00). Přesný čas dovozu bude upřesněn s řidičem v den dovozu.",
  "src.components.organisms.FaqPraha.delivery.content9":
    "Jsme flexibilní a rozumíme, že plány se mohou změnit. Čas či místo doručení naší {info} můžete změnit ještě v ten samý den do 12:00.",
  "src.components.organisms.FaqPraha.delivery.content9.bold":
    "krabičkové diety Praha",
  "src.components.organisms.FaqPraha.consumption.content1":
    "Některá jídla z naší {info} jsou určena k ohřevu, jiná ke konzumaci za studena. Pro lepší chuť však doporučujeme jídla ohřát. Informace o tom, které jídlo je třeba ohřát, zjistíte po načtení čárového kódu na krabičce.",
  "src.components.organisms.FaqPraha.consumption.content1.bold":
    "krabičkové diety Praha",
  "src.components.organisms.FaqPraha.consumption.content2.bold":
    "krabičkové diety Praha",
  "src.components.organisms.FaqPraha.consumption.content2":
    "Pokud ohříváte jídla z naší {info} v mikrovlnné troubě, doporučujeme propíchnout dírky do folie. Čas ohřevu se liší podle druhu jídla a velikosti porce, ale doporučujeme použít střední výkon (600–700 W) pro rovnoměrné prohřátí.",
  "src.components.organisms.FaqPraha.consumption.content3":
    "Jídla z naší {info} doporučujeme skladovat v lednici, ale můžete je vzít s sebou i do práce nebo na cesty. Jídla vydrží několik hodin mimo lednici bez ztráty chuti nebo kvality.",
  "src.components.organisms.FaqPraha.consumption.content3.bold":
    "krabičkové diety Praha",
  "src.components.organisms.FaqPraha.client.content1":
    "Ano, naše {info} je velmi flexibilní. Pokud potřebujete změnit své jídelníčky nebo upravit velikost porcí, můžete to snadno provést přes náš zákaznický portál nebo kontaktováním naší zákaznické podpory.",
  "src.components.organisms.FaqPraha.client.content1.bold":
    "krabičková dieta Praha",
  "src.components.organisms.FaqPraha.client.content2.bold":
    "krabičkové diety Praha",
  "src.components.organisms.FaqPraha.client.content2":
    "Umožňujeme vám přizpůsobit si menu podle vašich preferencí, alergií a dalších požadavků. Můžete si zvolit počet chodů, vynechat určité ingredience nebo upravit porce dle vaší potřeby v rámci naší {info}.",
  "src.components.organisms.FaqPraha.client.content3":
    "Pauzu si můžete dát kdykoliv, nestojí to nic, stačí jen zavolat na {info} nebo napsat e-mail na náš zákaznický servis {boldInfo}.",
  "src.components.organisms.FaqPraha.client.content3.bold": " 226 288 200",
  "src.components.organisms.FaqPraha.client.content3.boldInfo":
    "info@nutritionpro.cz.",
  "src.components.organisms.FaqPraha.client.content4":
    "Naše zákaznická podpora je vám k dispozici každý pracovní den od 08:00-18:00. Můžete nás kontaktovat telefonicky {info}, e-mailem {boldInfo}, nebo prostřednictvím našeho zákaznického portálu, kde se snažíme odpovědět co",
  "src.components.organisms.FaqPraha.client.content4.bold": "226 288 200",
  "src.components.organisms.FaqPraha.client.content4.boldInfo":
    "info@nutritionpro.cz",
  "src.components.moelecules.BenefitLabels.text3.praha":
    "Kalorie přes {head}, ne omáčky.",
  "src.components.moelecules.BenefitLabels.text4.praha":
    "Pauza {head}, stačí zavolat.",
  "src.components.moelecules.BenefitLabels.text1.praha":
    "Výběr ze {head} u každého chodu podle chuti",
  "src.components.moelecules.BenefitLabels.text2.praha":
    "Rozvážíme krabickou dietu v Praze ve {head}, nebo na odběrné místa.",
  "src.components.organisms.ProgramBlock.titleSecond.praha":
    " jeden z našich programů",
  "src.components.organisms.ProgramBlock.description.praha":
    "Užij si pestrou chuťovou paletu bez omezení. V rámci všech programů máš nyní možnost libovolné chody vyměnit.",
  "src.components.organisms.MenuBlock.titleFirst.praha": "Jaká jídla",
  "src.components.organisms.MenuBlock.titleSecond.praha": " tě s námi čekají?",
  "src.components.organisms.ComplateMenu.titleFirst.praha":
    "Nezávazně vyzkoušej ",
  "src.components.organisms.ComplateMenu.titleSecond.praha":
    "naši krabičkovou dietu na 2 dny",
  "src.components.organisms.ComplateMenu.description.praha":
    "Zanech nám na sebe kontakt, v pracovní době se ti do dvou hodin ozveme a během 10 minut vše individuálně domluvíme.",
  "src.components.organisms.ReviewBlock.description.praha":
    " Ke každému zákazníkovi přistupujeme individuálně, abychom zajistili nejlepší možné služby. | Krabičková dieta Praha",
  "src.components.organisms.HowCook.titleFirst.praha": "Proč NutritionPro - ",
  "src.components.moelecules.BenefitCityLabels.text3":
    "Kalorie přes bílkoviny, ne omáčky.",
  "src.components.moelecules.BenefitCityLabels.text4":
    "Pauza kdykoliv zdarma, stačí  zavolat.",
  "src.components.moelecules.BenefitCityLabels.text1":
    "Výběr ze {head} u každého chodu podle chuti.",
  "src.components.moelecules.BenefitCityLabels.subText2": "4 jídel",
  "src.components.moelecules.BenefitCityLabels.text2":
    "Rozvozy ráno i večer, domů nebo na výdejnu.",
  "src.components.organisms.FaqPraha.titleFirst": "Často ",
  "src.components.organisms.FaqPraha.titleSecond": "kladené dotazy",
  "src.components.organisms.HowWorks.daily.praha":
    "Nastavíme tvůj denní příjem podle tvých cílů",
  "src.components.organisms.HowWorks.algorithm.praha":
    "Speciální algoritmus sestaví jídelníček na míru",
  "src.components.organisms.HowWorks.cooking.praha": "Jídlo uvaříme",
  "src.components.organisms.HowWorks.delivery.praha":
    "Dovezeme ti domů či do práce",
  "src.components.organisms.HowWorks.goal.praha":
    " Následně dohlédneme na to, abys svého cíle dosáhl | Krabičková dieta Praha",
  "src.components.organisms.HowWorks.titleFirst.praha": "Jak funguje naše ",
  "src.components.organisms.HowWorks.titleSecond.praha":
    "krabičková dieta v Praze?",
  "src.components.organisms.HowCook.tab3.descripton4.praha":
    "Zároveň si můžeš mezi městy i měnit místo dovozu, tedy pokud tě čeká pracovní cesta, či návštěva příbuzných, krabičky ti doručíme kam bude potřeba.",
  "src.components.organisms.HowCook.tab3.description3.praha":
    "Odebírat můžeš na jednom z našich 10 odběrných míst, pohodlně umístěných na frekventovaných místech jako jsou fitness centra. | Krabičková dieta Praha",
  "src.components.organisms.HowCook.tab3.description2.praha":
    "Místo i čas dovozu si ještě ten samý den můžeš změnit, případně si nechat dovézt na odběrné místo. | Krabičková dieta Praha",
  "src.components.organisms.HowCook.tab3.descritpion1.praha":
    "V Praze vozíme v 2 hodinových oknech, které si sám navolíš, tedy nemusíš každý den čekat, jak to zrovna vyjde kurýrovi.",
  "src.components.organisms.HowCook.tab2.descripton4.praha":
    " V jídelníčku máme z každé kuchyně něco, od lasagních po butter chicken, ale neboj, pokud ti exotika nevyhovuje, vždy si můžeš jídlo nahradit, třeba zdravým gulášem. | Krabičková dieta Praha",
  "src.components.organisms.HowCook.tab2.description3.praha":
    "Složení jídel je kontrolováno naší nutriční specialistkou a dbáme na to, aby kalorie v jídle pocházeli z těch nejkvalitnějších zdrojů, bez použití umělých sladidel.",
  "src.components.organisms.HowCook.tab2.description2.praha":
    "Po celou dobu od výroby po doručení měříme zachování teplotního řetězce pro maximální kvalitu a čerstvost jídla při dodání.",
  "src.components.organisms.HowCook.tab2.descritpion1.praha":
    " Krabickovu Diety Praha připravujeme v nejmodernějších kuchyních pod kontrolou našeho technologa a kvalitáře, dle nejvyšších hygienických standardů.",
  "src.components.organisms.HowCook.tab1.descripton4.praha":
    "K jídlu si můžeš také dokoupit vitamínové balíčky v pohodlném jednodenním balení.",
  "src.components.organisms.HowCook.tab1.description3.praha":
    "Kalorie si můžeš libovolně volit od 800-2600 kcal s přesností na 100 kcal a počet chodů od 2 do 5 s možností dokoupit extra porci.",
  "src.components.organisms.HowCook.tab1.description2.praha":
    "Vše si navolíš jednoduše v našem intuitivním uživatelském prostředí, kde zároveň vidíš i jak změna ovlivní rozložení makronutrientů.",
  "src.components.organisms.HowCook.tab1.descritpion1.praha":
    "Máš chuť na sladkou, nebo slanou snídani? Dáš přednost lehkému salátu před teplou večeří? U nás je to na tobě!",

  "src.components.organisms.HowCook.tab1.title1.praha":
    "Vybírej až ze 4 jídel na každý chod",
  "src.components.organisms.HowCook.tab1.title2.praha":
    "Navol si jídelníček v klientské zóně",

  "src.components.organisms.HowCook.tab1.title3.praha":
    "Uzpůsob si příjem dle sebe",
  "src.components.organisms.HowCook.tab1.title4.praha":
    " Doplň si Krabickovu Diety Praha o extra vitamíny",

  "src.components.organisms.HowCook.tab2.title1.praha":
    "Neboj se o kvalitu přípravy",

  "src.components.organisms.HowCook.tab2.title2.praha":
    "Užij si maximální čerstvost",
  "src.components.organisms.HowCook.tab2.title3.praha":
    "Jez jen ty nejlepší kalorie",
  "src.components.organisms.HowCook.tab2.title4.praha":
    "Vyzkoušej rozmanitost jídel",
  "src.components.organisms.HowCook.tab3.title1.praha":
    "Krabičková dieta Praha - Vyber si kdy ti budeme vozit",

  "src.components.organisms.HowCook.tab3.title2.praha":
    "Krabičková dieta Praha - Flexibilně si změň místo a čas",
  "src.components.organisms.HowCook.tab3.title3.praha":
    "Krabičková dieta Praha - Využij jedno z našich odběrných míst",

  "src.components.organisms.HowCook.tab3.title4.praha":
    "Krabičková dieta Praha - Nech si přivézt do více než 10 měst v ČR",
  "src.components.molecules.OnlineOrderThiredStepSidebar.priceDayDemo":
    "Celková cena",
  "src.components.molecules.OnlineConfirmCard.priceDemo": "{price} Kč",
  "src.components.atoms.PricePerDay.titleDemo": "Celková cena",
  "src.components.organisms.ComplateMenu.redBtn": "Objednat online",

  "src.components.templates.OnlineOrderMobileTemplate.title1":
    "Spočítej si cenu",
  "src.components.templates.OnlineOrderMobileTemplate.title2":
    "Kontaktní údaje",
  "src.components.templates.OnlineOrderMobileTemplate.title3":
    "Doručení a platba",
  "src.components.templates.OnlineOrderMobileTemplate.description1":
    "K doručení krabiček zbývá už jen pár kroků.",
  "src.components.templates.OnlineOrderMobileTemplate.description2":
    "Pokračujte prosím vyplněním kontaktních údajů.",
  "src.components.templates.OnlineOrderMobileTemplate.description3":
    "Dokončete objednávku vyplněním času rozvozu a výběrem platby.",
  "src.components.templates.OnlineOrderMobileTemplate.back": "Zpět",
  "src.components.templates.OnlineOrderDesctopTemplate.sidebarTitile":
    "Shrnutí objednávky",
  "src.components.molecules.Steps.firstStep": "Výběr programu",
  "src.components.molecules.Steps.secondStep": "Kontaktní údaje",
  "src.components.molecules.Steps.lastStep": "Doručení a platba",
  "src.components.moelcules.OnlineOrderAddressCheck.city": "Vyplňte město",
  "src.components.moelcules.OnlineOrderAddressCheck.pick":
    "Vyber odberné místo",

  "src.component.atoms.AddressInput.success": "Doprava zdarma",
  "src.component.atoms.AddressInput.error":
    "Je nám líto! Zatím v této lokalitě nejsme.",
  "src.component.atoms.AddressInput.placeHolder": "Vyplňte vaši ulici a č.p.",
  "src.component.atoms.AddressInput.button": "Potvrdit",
  "src.components.molecules.FixedMark.rate": "  hodnocení",
  "src.component.molecules.ProgramDurationBlock.default": "Nejvýhodnější",
  "src.component.molecules.ProgramDurationBlock.demo": "Sleva 50%",
  "src.component.molecules.ProgramDurationBlock.price": "{price} Kč",
  "src.component.molecules.ProgramDurationBlock.day": "den",
  "src.components.organisms.PickUpPointsModal.text": "Odběrná místa",

  "src.components.organisms.HomePgaeSliderDefault.title": "KRABIČKOVÁ DIETA",
  "src.components.organisms.HomePgaeSliderDefault.promoCode":
    "OD 94 KČ ZA CHOD",
  "src.components.organisms.HomePgaeSliderDefault.priceBtn": "JDU NA TO",
  "src.components.organisms.HomePgaeSliderDefault.description":
    "Konečně krabičková dieta, která se opravdu vyplatí!",
  "src.components.orhanisms.HomePageSliderDemo.btn": "JDU NA TO",
  "src.components.orhanisms.HomePageSliderDemo.title":
    "Vyzkoušej nezávazně jen za 749 Kč",
  "src.components.orhanisms.HomePageSliderDemo.priceText":
    "Čeká tě celkem 10 chodů, nezávazně a se slevou ",
  "src.components.orhanisms.HomePageSliderDemo.price": " 45  %.",
  "src.components.orhanisms.HomePageSliderDemo.description":
    "Ochutnej ihned, ať ti nabídka neuteče.",
  "src.component.molecules.ProgramDurationBlock.days": "dní",
  "src.components.molecules.FixedMark.rated": "Nejlépe hodnocená",
  "src.components.molecules.FixedMark.boxes": "krabičková dieta",
  "src.components.molecules.FixedMark.count": "kladných zákaznických hodnocení",

  "src.components.routes.pages.Terms.content2.description1.a":
    "a. na telefonním čísle +420 226 288 200, kdy k uzavření kupní smlouvy dojde potvrzenímobjednávky ze strany Provozovatele při daném hovoru,",
  "src.components.routes.pages.Terms.content2.description1.b":
    "b. prostřednictvím e-mailu na e-mailové adresy uvedené na internetových stránkách www.nutritionpro.cz, kdy k uzavření kupní smlouvy dojde potvrzením objednávky ze strany Provozovatele formou e-mailu, telefonicky nebo dodáním Programu NutritionPro (v případě opětovné dodávky Programu NutritionPro, kdy Klient reaguje novou objednávkou na e-mail Provozovatele oznamující blížící se konec předchozí objednávky),",
  "src.components.routes.pages.Terms.content2.description1.c":
    "c. prostřednictvím on-line formuláře umístěného na internetových stránkách www.nutritionpro.cz, kdy k uzavření kupní smlouvy dojde k potvrzením objednávky ze strany Provozovatele telefonicky nebo emailem.",
  "src.components.routes.pages.Terms.content4.description7":
    "7. Pokud je na vaší adrese možné zanechat objednávku na vašem pozemku, tzv. `na kliku`, je možné se domluvit s našimi operátory na této výjimce. Tímto způsobem předání zaniká odpovědnost Provozovatele za produkt, jeho kvalitu a případně odcizení již není předmětem naší odpovědnosti. Objednávky předané tímto způsobem budou fotodokumentovány kurýrem pro naši evidenci a případné prokázání splnění našich závazků.",
  "src.components.routes.pages.Terms.content4.description8":
    "8. Pokud Klient nepřebere svou objednávku setu pokrmů během smluveného rozvozu, tedy ve smluveném čase a místě, je Klient v případě vyzvání ze strany Provozovatele povinen uhradit Provozovateli paušální náhradu škody za každou nepřevzatou objednávku, a to ve výši 50 Kč za jedno nepřevzetí. Tato částka představuje paušalizovanou náhradu škody za vícepráce s tím spojené.",

  "src.components.routes.pages.Terms.content5.description5":
    "5. Klient je oprávněn odstoupit od Smlouvy do 14 dnů od okamžiku jejího uzavření. V tomto případě Provozovatel vrátí Klientovi zaslané prostředky za nevyužité/nevybrané dny objednávky zpět na jeho účet nejpozději do 30 dnů ode dne ukončení smlouvy.",
  "src.components.routes.pages.Terms.content5.description6":
    "6. Klient je oprávněn od Smlouvy odstoupit i po uplynutí lhůty uvedené v předchozím odstavci. Pokud však Klient odstoupí od Smlouvy po lhůtě uvedené v předchozím odstavci, je Provozovatel oprávněn účtovat Klientovi storno poplatek ve výši až 75% z nevyužitých/nevybraných dnů objednávky. Zbytek prostředků vrátí Provozovatel Klientovi zpět na jeho účet nejpozději do 30 dnů ode dne ukončení smlouvy.",
  "src.components.routes.pages.Terms.content5.description7":
    "7. Všechny reklamace budou ze strany Provozovatele vyhodnoceny do 30 dní od data jejího uplatnění.",
  "src.components.routes.pages.Terms.content5.description8":
    "8. Předmětem reklamace může být jak kvalita pokrmů, tak s tím spojené služby (rozvoz, úroveň komunikace apod.)",
  "src.components.routes.pages.Terms.content5.description9":
    "9. Provozovatel je oprávněn odstoupit od Smlouvy v případě, že je Klient v prodlení s úhradou jakéhokoli peněžitého plnění Provozovateli",

  "src.components.organisms.Faq.delivery.content8.text1":
    "Praha: na výběr máte z 2hodinových oken, dovážíme v čase 17:00 - 22:00",
  "src.components.organisms.Faq.delivery.content8.text2":
    "Plzeň: zde nabízíme 3 možnosti časových oken: ",
  "src.components.organisms.Faq.delivery.content8.text3":
    "17:00 – 20:00 | 18:00 – 21:00 | 19:00 – 21:00",
  "src.components.organisms.Faq.delivery.content8.text4":
    "Liberec: 17:00 – 22:00",
  "src.components.organisms.Faq.delivery.content8.text5":
    "Mladá Boleslav: 16:00 – 22:00",
  "src.components.organisms.Faq.delivery.content8.text6":
    "Jablonec nad Nisou: 18:00 – 22:00",
  "src.components.organisms.Faq.delivery.content8.text7":
    "Pardubice: 16:30 – 22:00",
  "src.components.organisms.Faq.delivery.content8.text8":
    "Hradec Králové: 16:30 –22:00",
  "src.components.routes.pages.SwitchBoxes.title": "Switch boxes",
  "src.components.molecules.SwitchCompainSlider.title1": "Objednej si program",
  "src.components.molecules.SwitchCompainSlider.title2": "Zašli nám fakturu",
  "src.components.molecules.SwitchCompainSlider.title3": "Získej voucher",
  "src.components.routes.pages.AceTerms.title": "Ace page",
  "src.components.molecules.OnlineOrderAddressCheck.checkbox":
    "Čas od času bychom vám rádi zaslali slevy, nabídky a marketingové informace. Zaškrtnuté políčko znamená, že s tím souhlasíte. Nebojte se, můžete se kdykoliv později odhlásit.",
  "src.components.molecules.OnlineOrderSecondStepSidebar.terms1.text1": " a ",
  "src.components.molecules.OnlineOrderSecondStepSidebar.terms1.link2":
    "politikou ochrany osobních údajů.",
  "src.components.routes.pages.DietBrno.HowCook.title":
    " krabičková dieta Brno?",
  "src.components.routes.pages.DietBrno.HowWorks.title":
    "funguje naše krabičková dieta v Brně?",
  "src.components.routes.pages.DietBrno.ReviewBlock.title":
    " Ke každému zákazníkovi přistupujeme individuálně, abychom zajistili nejlepší možné služby. | Krabičková dieta Brno",
  "src.components.organisms.Faq.menu.content1.brno":
    "Naše krabičková dieta se vyznačuje individuálním přístupem, protože věříme, že každý člověk je jedinečný a potřebuje specifický kalorický příjem, aby dosáhl svých cílů zdravou cestou. Menu pro 70kg ženu, která chce zhubnout, bude vypadat úplně jinak než pro 90kg muže, který se snaží nabrat svalovou hmotu. Naše krabičková dieta je navržena přesně tak, aby odpovídala vašim individuálním potřebám a cílům, což je něco, co běžné krabičkové diety často opomíjejí.",
  "src.components.organisms.Faq.menu.subTitle1.brno":
    "V čem je naše krabičková dieta v Brně jiná?",

  "src.components.organisms.Faq.menu.subTitle2.brno":
    "Kdo sestavuje jídelníček pro krabičkovou dietu v Brně?",
  "src.components.organisms.Faq.menu.content2.brno":
    "Jídelníček pro naši krabičkovou dietu je sestavován naším speciálním algoritmem, který pečlivě zohledňuje potřeby vašeho těla a vaše cíle. Naše odbornice na výživu, Jitka Dokoupilová, dohlíží na složení jednotlivých jídel a zajišťuje, že dosáhnete požadovaných výsledků.",
  "src.components.organisms.Faq.menu.subTitle3.brno":
    "Jak vypadá jídelníček v rámci krabičkové diety v Brně?",
  "src.components.organisms.Faq.menu.content3.brno":
    "Naše krabičková dieta je založena na zásadách vyvážené stravy. Jídelníček je pestrý a chutný, s více než 500 různými jídly, která se pravidelně obměňují. Neustále aktualizujeme naši databázi jídel, abychom vám mohli nabídnout nové a zajímavé možnosti, takže se naše jídla nikdy nepřejí. Podívejte se na ukázku jídelníčku, který nabízíme.",

  "src.components.organisms.Faq.materials.subTitle3.brno":
    "Kdy připravujete jídla pro krabičkovou dietu v Brně?",
  "src.components.organisms.Faq.materials.content3.brno":
    "Příprava jídel pro naši krabičkovou dietu v Brně probíhá v den rozvozu. Jídla připravujeme v neděli na pondělí a úterý, v úterý na středu a čtvrtek a ve čtvrtek na pátek a sobotu.",
  "src.components.organisms.Faq.materials.subTitle4.title":
    "Máte v nabídce i dezerty v rámci krabičkové diety Brno?",
  "src.components.organisms.Faq.materials.content4.title":
    "Ano, naše krabičková dieta zahrnuje i výborné dezerty, které jsou přesně spočítány tak, aby zapadly do vašeho osobního jídelníčku.",
  "src.components.molecules.OnlineOrderThiredStepSidebar.pluxeeConfirm":
    "Mám zájem",

  "src.components.routes.pages.DietPlzen.HowCook.title":
    " NutritionPro - krabičková dieta Plzeň?",
  "src.components.routes.pages.DietPlzen.ReviewBlock.description":
    "Ke každému zákazníkovi přistupujeme individuálně, abychom zajistili nejlepší možné služby. | Krabičková dieta Plzeň",
  "src.components.routes.pages.DietPlzen.HowWorks.title":
    "funguje naše krabičková dieta v Plzni?",
  "src.components.routes.pages.DietHradec.HowCook.title":
    " NutritionPro - krabičková dieta Hradec Králové?",
  "src.components.routes.pages.DietHradec.ReviewBlock.description":
    "Ke každému zákazníkovi přistupujeme individuálně, abychom zajistili nejlepší možné služby. | Krabičková dieta Hradec Králové",
  "src.components.routes.pages.DietHradec.HowWorks.title":
    " funguje naše krabičková dieta v Hradci Králové?",
  "src.components.routes.pages.DietLiberec.HowCook.title":
    " NutritionPro - krabičková dieta Liberec?",
  "src.components.routes.pages.DietLiberec.ReviewBlock.description":
    "Ke každému zákazníkovi přistupujeme individuálně, abychom zajistili nejlepší možné služby. | Krabičková dieta Liberec",
  "src.components.routes.pages.DietLiberec.HowWorks.title":
    "funguje naše krabičková dieta v Liberci?",
  "src.components.routes.pages.DietParadubice.ReviewBlock.description":
    " Ke každému zákazníkovi přistupujeme individuálně, abychom zajistili nejlepší možné služby. | Krabičková dieta Pardubice",
  "src.components.routes.pages.DietParadubice.HowWorks.title":
    "funguje naše krabičková dieta v Pardubicích?",
  "src.components.routes.pages.DietParadubice.HowCook.title":
    "NutritionPro - krabičková dieta Pardubice?",
  "src.components.routes.pages.DietSredniCechi.HowCook.title":
    "NutritionPro - krabičková dieta Střední Čechy?",
  "src.components.routes.pages.DietSredniCechi.ReviewBlock.description":
    "Ke každému zákazníkovi přistupujeme individuálně, abychom zajistili nejlepší možné služby. | Krabičková dieta Střední Čechy",
  "src.components.routes.pages.DietSredniCechi.HowWorks.title":
    "funguje naše krabičková dieta ve Středních Čechách?",
  "src.components.routes.pages.DietMlda.HowCook.title":
    "NutritionPro - krabičková dieta Mladá Boleslav?",
  "src.components.routes.pages.DietMlda.ReviewBlock.description":
    "Ke každému zákazníkovi přistupujeme individuálně, abychom zajistili nejlepší možné služby. | Krabičková dieta Mladá Boleslav",
  "src.components.routes.pages.DietMlda.HowWorks.title":
    "funguje naše krabičková dieta v Mladé Boleslavi?",
  "src.components.routes.pages.DietJablonec.HowCook.title":
    " NutritionPro - krabičková dieta Jablonec nad Nisou?",
  "src.components.routes.pages.DietJablonecNisou.ReviewBlock.description":
    " Ke každému zákazníkovi přistupujeme individuálně, abychom zajistili nejlepší možné služby. | Krabičková dieta Jablonec",
  "src.components.routes.pages.DietJablonecNisou.HowWorks.title":
    "funguje naše krabičková dieta v Jablonci nad Nisou?",
  "src.components.organisms.Faq.menu.subTitle1.plzen":
    "V čem je naše krabičková dieta v Plzni jiná?",
  "src.components.organisms.Faq.menu.subTitle1.liberec":
    "V čem je naše krabičková dieta v Liberci jiná?",
  "src.components.organisms.Faq.menu.subTitle1.hradec":
    "V čem je naše krabičková dieta v Hradci Králové jiná?",
  "src.components.organisms.Faq.menu.subTitle1.sredni":
    "V čem je naše krabičková dieta ve Středních Čechách jiná?",
  "src.components.organisms.Faq.menu.subTitle1.mlda":
    "V čem je naše krabičková dieta v Mladé Boleslavi jiná?",
  "src.components.organisms.Faq.menu.subTitle1.paradubice":
    "V čem je naše krabičková dieta v Pardubicích jiná?",
  "src.components.organisms.Faq.menu.subTitle1.jablonec":
    "V čem je naše krabičková dieta v Jablonci nad Nisou jiná?",
  "src.components.organisms.Faq.menu.content1.plzen":
    "Naše krabičková dieta se vyznačuje individuálním přístupem, protože věříme, že každý člověk je jedinečný a potřebuje specifický kalorický příjem, aby dosáhl svých cílů zdravou cestou. Menu pro 70kg ženu, která chce zhubnout, bude vypadat úplně jinak než pro 90kg muže, který se snaží nabrat svalovou hmotu. Naše krabičková dieta je navržena přesně tak, aby odpovídala vašim individuálním potřebám a cílům, což je něco, co běžné krabičkové diety často opomíjejí.",
  "src.components.organisms.Faq.menu.content1.liberec":
    "Naše krabičková dieta v Liberci se vyznačuje individuálním přístupem, protože věříme, že každý člověk je jedinečný a potřebuje specifický kalorický příjem, aby dosáhl svých cílů zdravou cestou. Menu pro 70kg ženu, která chce zhubnout, bude vypadat úplně jinak než pro 90kg muže, který se snaží nabrat svalovou hmotu. Naše krabičková dieta je navržena přesně tak, aby odpovídala vašim individuálním potřebám a cílům, což běžné krabičkové diety často opomíjejí.",
  "src.components.organisms.Faq.menu.content1.sredni":
    "Naše krabičková dieta ve Středních Čechách se vyznačuje individuálním přístupem, protože věříme, že každý člověk je jedinečný a potřebuje specifický kalorický příjem, aby dosáhl svých cílů zdravou cestou. Menu pro 70kg ženu, která chce zhubnout, bude vypadat úplně jinak než pro 90kg muže, který se snaží nabrat svalovou hmotu. Naše krabičková dieta je navržena přesně tak, aby odpovídala vašim individuálním potřebám a cílům, což běžné krabičkové diety často opomíjejí.",
  "src.components.organisms.Faq.menu.content1.paradubice":
    "Naše krabičková dieta v Pardubicích se vyznačuje individuálním přístupem, protože věříme, že každý člověk je jedinečný a potřebuje specifický kalorický příjem, aby dosáhl svých cílů zdravou cestou. Menu pro 70kg ženu, která chce zhubnout, bude vypadat úplně jinak než pro 90kg muže, který se snaží nabrat svalovou hmotu. Naše krabičková dieta je navržena přesně tak, aby odpovídala vašim individuálním potřebám a cílům, což běžné krabičkové diety často opomíjejí.",
  "src.components.organisms.Faq.menu.content1.mlda":
    "Naše krabičková dieta v Mladé Boleslavi se vyznačuje individuálním přístupem, protože věříme, že každý člověk je jedinečný a potřebuje specifický kalorický příjem, aby dosáhl svých cílů zdravou cestou. Menu pro 70kg ženu, která chce zhubnout, bude vypadat úplně jinak než pro 90kg muže, který se snaží nabrat svalovou hmotu. Naše krabičková dieta je navržena přesně tak, aby odpovídala vašim individuálním potřebám a cílům, což běžné krabičkové diety často opomíjejí.",
  "src.components.organisms.Faq.menu.content1.hradec":
    "Naše krabičková dieta se vyznačuje individuálním přístupem, protože věříme, že každý člověk je jedinečný a potřebuje specifický kalorický příjem, aby dosáhl svých cílů zdravou cestou. Menu pro 70kg ženu, která chce zhubnout, bude vypadat úplně jinak než pro 90kg muže, který se snaží nabrat svalovou hmotu. Naše krabičková dieta je navržena přesně tak, aby odpovídala vašim individuálním potřebám a cílům, což běžné krabičkové diety často opomíjejí. ",
  "src.components.organisms.Faq.menu.content1.jablonec":
    "Naše krabičková dieta v Jablonci se vyznačuje individuálním přístupem, protože věříme, že každý člověk je jedinečný a potřebuje specifický kalorický příjem, aby dosáhl svých cílů zdravou cestou. Menu pro 70kg ženu, která chce zhubnout, bude vypadat úplně jinak než pro 90kg muže, který se snaží nabrat svalovou hmotu. Naše krabičková dieta je navržena přesně tak, aby odpovídala vašim individuálním potřebám a cílům, což běžné krabičkové diety často opomíjejí.",
  "src.components.organisms.Faq.menu.subTitle2.plzen":
    "Kdo sestavuje jídelníček pro krabičkovou dietu v Plzni?",
  "src.components.organisms.Faq.menu.content2.plzen":
    "Jídelníček pro naši krabičkovou dietu je sestavován naším speciálním algoritmem, který pečlivě zohledňuje potřeby vašeho těla a vaše cíle. Naše odbornice na výživu, Jitka Dokoupilová, dohlíží na složení jednotlivých jídel a zajišťuje, že dosáhnete požadovaných výsledků.",
  "src.components.organisms.Faq.menu.subTitle2.hradec":
    "Kdo sestavuje jídelníček pro krabičkovou dietu v Hradci Králové?",
  "src.components.organisms.Faq.menu.content2.hradec":
    "Jídelníček pro naši krabičkovou dietu je sestavován naším speciálním algoritmem, který pečlivě zohledňuje potřeby vašeho těla a vaše cíle. Naše odbornice na výživu, Jitka Dokoupilová, dohlíží na složení jednotlivých jídel a zajišťuje, že se stravou dosáhnete požadovaných výsledků.",
  "src.components.organisms.Faq.menu.subTitle2.liberec":
    "Kdo sestavuje jídelníček pro krabičkovou dietu v Liberci?",
  "src.components.organisms.Faq.menu.content2.liberec":
    "Jídelníček pro naši krabičkovou dietu v Liberci je sestavován naším speciálním algoritmem, který pečlivě zohledňuje potřeby vašeho těla a vaše cíle. Naše odbornice na výživu, Jitka Dokoupilová, dohlíží na složení jednotlivých jídel a zajišťuje, že se stravou dosáhnete požadovaných výsledků.",
  "src.components.organisms.Faq.menu.subTitle2.paradubice":
    "Kdo sestavuje jídelníček pro krabičkovou dietu v Pardubicích?",
  "src.components.organisms.Faq.menu.content2.paradubice":
    "Jídelníček pro naši krabičkovou dietu v Pardubicích je sestavován naším speciálním algoritmem, který pečlivě zohledňuje potřeby vašeho těla a vaše cíle. Naše odbornice na výživu, Jitka Dokoupilová, dohlíží na složení jednotlivých jídel a zajišťuje, že se stravou dosáhnete požadovaných výsledků.",
  "src.components.organisms.Faq.menu.subTitle2.sredni":
    "Kdo sestavuje jídelníček pro krabičkovou dietu ve Středních Čechách?",
  "src.components.organisms.Faq.menu.content2.sredni":
    "Jídelníček pro naši krabičkovou dietu ve Středních Čechách je sestavován naším speciálním algoritmem, který pečlivě zohledňuje potřeby vašeho těla a vaše cíle. Naše odbornice na výživu, Jitka Dokoupilová, dohlíží na složení jednotlivých jídel a zajišťuje, že se stravou dosáhnete požadovaných výsledků.",
  "src.components.organisms.Faq.menu.subTitle2.mlda":
    "Kdo sestavuje jídelníček pro krabičkovou dietu v Mladé Boleslavi?",
  "src.components.organisms.Faq.menu.content2.mlda":
    "Jídelníček pro naši krabičkovou dietu v Mladé Boleslavi je sestavován naším speciálním algoritmem, který pečlivě zohledňuje potřeby vašeho těla a vaše cíle. Naše odbornice na výživu, Jitka Dokoupilová, dohlíží na složení jednotlivých jídel a zajišťuje, že se stravou dosáhnete požadovaných výsledků.",
  "src.components.organisms.Faq.menu.subTitle2.jablonec":
    "Kdo sestavuje jídelníček pro krabičkovou dietu v Jablonci nad Nisou?",
  "src.components.organisms.Faq.menu.content2.jablonec":
    "Jídelníček pro naši krabičkovou dietu v Jablonci je sestavován naším speciálním algoritmem, který pečlivě zohledňuje potřeby vašeho těla a vaše cíle. Naše odbornice na výživu, Jitka Dokoupilová, dohlíží na složení jednotlivých jídel a zajišťuje, že se stravou dosáhnete požadovaných výsledků.",
  "src.components.organisms.Faq.menu.subTitle3.plzen":
    "Jak vypadá jídelníček v rámci krabičkové diety v Plzni?",
  "src.components.organisms.Faq.menu.content3.plzen":
    "Naše krabičková dieta je založena na zásadách vyvážené stravy. Jídelníček je pestrý a chutný, s více než 500 různými jídly, která se pravidelně obměňují. Neustále aktualizujeme naši databázi jídel, abychom vám mohli nabídnout nové a zajímavé možnosti, takže se naše jídla nikdy nepřejí. Podívejte se na ukázku jídelníčku, který nabízíme.",
  "src.components.organisms.Faq.menu.subTitle3.hradec":
    "Jak vypadá jídelníček v rámci krabičkové diety v Hradci Králové?",
  "src.components.organisms.Faq.menu.content3.hradec":
    "Naše krabičková dieta je založena na zásadách vyvážené stravy. Jídelníček je pestrý a chutný, s více než 500 různými jídly, která se pravidelně obměňují. Neustále aktualizujeme naši databázi jídel, abychom vám mohli nabídnout nové a zajímavé možnosti, vám se tak naše jídla neomrzela. Podívejte se na ukázku jídelníčku, abyste věděli, jaká jídla můžete čekat.",
  "src.components.organisms.Faq.menu.subTitle3.liberec":
    "Jak vypadá jídelníček v rámci krabičkové diety v Liberci?",
  "src.components.organisms.Faq.menu.content3.liberec":
    "Naše krabičková dieta v Liberci je založena na zásadách vyvážené stravy. Jídelníček je pestrý a chutný, s více než 500 různými jídly, která se pravidelně obměňují. Neustále aktualizujeme naši databázi jídel, abychom vám mohli nabídnout nové a zajímavé možnosti, vám se tak naše jídla neomrzela. Podívejte se na ukázku jídelníčku, abyste věděli, jaká jídla můžete čekat.",
  "src.components.organisms.Faq.menu.subTitle3.paradubice":
    "Jak vypadá jídelníček v rámci krabičkové diety v Pardubicích?",
  "src.components.organisms.Faq.menu.content3.paradubice":
    "Naše krabičková dieta v Pardubicích je založena na zásadách vyvážené stravy. Jídelníček je pestrý a chutný, s více než 500 různými jídly, která se pravidelně obměňují. Neustále aktualizujeme naši databázi jídel, abychom vám mohli nabídnout nové a zajímavé možnosti, vám se tak naše jídla neomrzela. Podívejte se na ukázku jídelníčku, abyste věděli, jaká jídla můžete čekat.",
  "src.components.organisms.Faq.menu.subTitle3.sredni":
    "Jak vypadá jídelníček v rámci krabičkové diety ve Středních Čechách?",
  "src.components.organisms.Faq.menu.content3.sredni":
    "Naše krabičková dieta ve Středních Čechách je založena na zásadách vyvážené stravy. Jídelníček je pestrý a chutný, s více než 500 různými jídly, která se pravidelně obměňují. Neustále aktualizujeme naši databázi jídel, abychom vám mohli nabídnout nové a zajímavé možnosti, vám se tak naše jídla neomrzela. Podívejte se na ukázku jídelníčku, abyste věděli, jaká jídla můžete čekat.",
  "src.components.organisms.Faq.menu.subTitle3.mlda":
    "Jak vypadá jídelníček v rámci krabičkové diety v Mladé Boleslavi?",
  "src.components.organisms.Faq.menu.content3.mlda":
    "Naše krabičková dieta v Mladé Boleslavi je založena na zásadách vyvážené stravy. Jídelníček je pestrý a chutný, s více než 500 různými jídly, která se pravidelně obměňují. Neustále aktualizujeme naši databázi jídel, abychom vám mohli nabídnout nové a zajímavé možnosti, vám se tak naše jídla neomrzela. Podívejte se na ukázku jídelníčku, abyste věděli, jaká jídla můžete čekat.",
  "src.components.organisms.Faq.menu.subTitle3.jablonec":
    "Jak vypadá jídelníček v rámci krabičkové diety v Jablonci nad Nisou?",
  "src.components.organisms.Faq.menu.content3.jablonec":
    "Naše krabičková dieta v Jablonci je založena na zásadách vyvážené stravy. Jídelníček je pestrý a chutný, s více než 500 různými jídly, která se pravidelně obměňují. Neustále aktualizujeme naši databázi jídel, abychom vám mohli nabídnout nové a zajímavé možnosti, vám se tak naše jídla neomrzela. Podívejte se na ukázku jídelníčku, abyste věděli, jaká jídla můžete čekat.",
  "src.components.organisms.Faq.materials.subTitle3.plzen":
    "Kdy připravujete jídla pro krabičkovou dietu v Plzni?",
  "src.components.organisms.Faq.materials.content3.plzen":
    "Příprava jídel pro naši krabičkovou dietu v Plzni probíhá v den rozvozu. Jídla připravujeme v neděli na pondělí a úterý, v úterý na středu a čtvrtek a ve čtvrtek na pátek a sobotu.",

  "src.components.organisms.Faq.materials.subTitle3.hradec":
    "Kdy připravujete jídla pro krabičkovou dietu v Hradci Králové?",
  "src.components.organisms.Faq.materials.content3.hradec":
    "Příprava jídel pro naši krabičkovou dietu v Hradci Králové probíhá přímo v den rozvozu. Jídla připravujeme v neděli na pondělí a úterý, v úterý na středu a čtvrtek a ve čtvrtek na pátek a sobotu.",

  "src.components.organisms.Faq.materials.subTitle3.liberec":
    "Kdy připravujete jídla pro krabičkovou dietu v Liberci?",
  "src.components.organisms.Faq.materials.content3.liberec":
    "Příprava jídel pro naši krabičkovou dietu v Liberci probíhá přímo v den rozvozu. Jídla připravujeme v neděli na pondělí a úterý, v úterý na středu a čtvrtek a ve čtvrtek na pátek a sobotu.",

  "src.components.organisms.Faq.materials.subTitle3.paradubice":
    "Kdy připravujete jídla pro krabičkovou dietu v Pardubicích?",
  "src.components.organisms.Faq.materials.content3.paradubice":
    "Příprava jídel pro naši krabičkovou dietu v Pardubicích probíhá přímo v den rozvozu. Jídla připravujeme v neděli na pondělí a úterý, v úterý na středu a čtvrtek a ve čtvrtek na pátek a sobotu.",

  "src.components.organisms.Faq.materials.subTitle3.sredni":
    "Kdy připravujete jídla pro krabičkovou dietu ve Středních Čechách?",
  "src.components.organisms.Faq.materials.content3.sredni":
    "Příprava jídel pro naši krabičkovou dietu ve Středních Čechách probíhá přímo v den rozvozu. Jídla připravujeme v neděli na pondělí a úterý, v úterý na středu a čtvrtek a ve čtvrtek na pátek a sobotu.",

  "src.components.organisms.Faq.materials.subTitle3.mlda":
    "Kdy připravujete jídla pro krabičkovou dietu v Mladé Boleslavi?",
  "src.components.organisms.Faq.materials.content3.mlda":
    "Příprava jídel pro naši krabičkovou dietu v Mladé Boleslavi probíhá přímo v den rozvozu. Jídla připravujeme v neděli na pondělí a úterý, v úterý na středu a čtvrtek a ve čtvrtek na pátek a sobotu.",

  "src.components.organisms.Faq.materials.subTitle3.jablonec":
    "Kdy připravujete jídla pro krabičkovou dietu v Jablonci nad Nisou?",
  "src.components.organisms.Faq.materials.content3.jablonec":
    "Příprava jídel pro naši krabičkovou dietu v Jablonci probíhá přímo v den rozvozu. Jídla připravujeme v neděli na pondělí a úterý, v úterý na středu a čtvrtek a ve čtvrtek na pátek a sobotu.",
  "src.components.organisms.Faq.materials.subTitle4.title.plzen":
    "Máte v nabídce i dezerty v rámci krabičkové diety Plzeň?",
  "src.components.organisms.Faq.materials.content4.title.plzen":
    "Ano, naše krabičková dieta zahrnuje i výborné dezerty, které jsou přesně spočítány tak, aby zapadly do vašeho osobního jídelníčku.",

  "src.components.organisms.Faq.materials.subTitle4.title.hradec":
    "Máte v nabídce i dezerty v rámci krabičkové diety Hradec Králové?",
  "src.components.organisms.Faq.materials.content4.title.hradec":
    "Ano, naše krabičková dieta zahrnuje i výborné dezerty, které jsou přesně spočítány tak, aby zapadly do vašeho osobního jídelníčku.",

  "src.components.organisms.Faq.materials.subTitle4.title.liberec":
    "Máte v nabídce i dezerty v rámci krabičkové diety Liberec?",
  "src.components.organisms.Faq.materials.content4.title.liberec":
    "Ano, naše krabičková dieta v Liberci zahrnuje i výborné dezerty, které jsou přesně spočítány tak, aby zapadly do vašeho osobního jídelníčku.",
  "src.components.organisms.Faq.materials.subTitle4.title.paradubice":
    "Máte v nabídce i dezerty v rámci krabičkové diety Pardubice?",
  "src.components.organisms.Faq.materials.content4.title.paradubice":
    "Ano, naše krabičková dieta v Pardubicích zahrnuje i výborné dezerty, které jsou přesně spočítány tak, aby zapadly do vašeho osobního jídelníčku.",

  "src.components.organisms.Faq.materials.subTitle4.title.sredni":
    "Máte v nabídce i dezerty v rámci krabičkové diety Střední Čechy?",
  "src.components.organisms.Faq.materials.content4.title.sredni":
    "Ano, naše krabičková dieta ve Středních Čechách zahrnuje i výborné dezerty, které jsou přesně spočítány tak, aby zapadly do vašeho osobního jídelníčku.",

  "src.components.organisms.Faq.materials.subTitle4.title.mlda":
    "Máte v nabídce i dezerty v rámci krabičkové diety Mladá Boleslav?",
  "src.components.organisms.Faq.materials.content4.title.mlda":
    "Ano, naše krabičková dieta v Mladé Boleslavi zahrnuje i výborné dezerty, které jsou přesně spočítány tak, aby zapadly do vašeho osobního jídelníčku.",

  "src.components.organisms.Faq.materials.subTitle4.title.jablonec":
    "Máte v nabídce i dezerty v rámci krabičkové diety Jablonec nad Nisou?",
  "src.components.organisms.Faq.materials.content4.title.jablonec":
    "Ano, naše krabičková dieta v Jablonci zahrnuje i výborné dezerty, které jsou přesně spočítány tak, aby zapadly do vašeho osobního jídelníčku.",

  "src.components.moelecules.BenefitLabels.text2.hradec":
    "Rozvážíme jídlo ve {head}, nebo na odběrné místo.",
  "src.components.moelecules.BenefitLabels.subText2.hradec":
    "2 hodinových oknech",

  "src.components.moelecules.BenefitLabels.text2.liberec":
    "Rozvážíme jídlo ve {head}, nebo na odběrné místo.",
  "src.components.moelecules.BenefitLabels.subText2.liberec":
    "2 hodinových oknech",

  "src.components.moelecules.BenefitLabels.text2.paradubice":
    "Rozvážíme jídlo ve {head}.",
  "src.components.moelecules.BenefitLabels.subText2.paradubice":
    "2 hodinových oknech",

  "src.components.moelecules.BenefitLabels.text2.sredni":
    "Rozvážíme jídlo ve {head}.",
  "src.components.moelecules.BenefitLabels.subText2.sredni":
    "večerních hodinách",

  "src.components.moelecules.BenefitLabels.text2.mlda":
    "Rozvážíme jídlo ve {head}, nebo na odběrné místo.",
  "src.components.moelecules.BenefitLabels.subText2.mlda":
    "2 hodinových oknech",

  "src.components.moelecules.BenefitLabels.text2.jablonec":
    "Rozvážíme jídlo ve {head}.",
  "src.components.moelecules.BenefitLabels.subText2.jablonec":
    "večerních hodinách",

  "src.components.moelecules.BenefitLabels.text2.brno":
    "Rozvážíme jídlo ve {head}, nebo na odběrná místa.",
  "src.components.moelecules.BenefitLabels.subText2.brno":
    "2 hodinových oknech",
  "src.components.moelecules.BenefitLabels.text2.plzen":
    "Rozvážíme jídlo ve {head}, nebo na odběrná místa.",
  "src.components.moelecules.BenefitLabels.subText2.plzen":
    "2 hodinových oknech",

  "src.components.organisms.Faq.delivery.content8.text2.plzen":
    "V Plzni nabízíme 3 možnosti časových oken:",
  "src.components.organisms.Faq.delivery.content8.text3.plzen":
    "17:00 – 20:00 | 18:00 – 21:00 | 19:00 – 21:00",
  "src.components.organisms.Faq.delivery.content8.text4.plzen":
    "Vybereš si, které ti sedí nejvíce, a na konkrétním čase se domluvíš přímo s kurýrem.",
  "src.components.organisms.Faq.delivery.content9.plzen":
    "Jsme flexibilní a rozumíme, že plány se mohou změnit. Čas či místo doručení naší {info} můžete změnit ještě v ten samý den do 10:00.",
  "src.components.organisms.Faq.delivery.content9.liberec":
    "Jsme flexibilní a rozumíme, že plány se mohou změnit. Čas či místo doručení naší {info} můžete změnit ještě v ten samý den do 10:00.",
  "src.components.organisms.Faq.delivery.content8.text1.liberec":
    "V Liberci ti jídlo doručíme v časovém rozpětí 17:00 – 22:00, na konkrétním čase se domluvíš přímo s kurýrem.",
  "src.components.organisms.Faq.delivery.content8.text1.hradec":
    "V Hradci Králové ti jídlo doručíme v časovém rozpětí 16:30 – 22:00, na konkrétním čase se domluvíš přímo s kurýrem.",
  "src.components.organisms.Faq.delivery.content9.hradec":
    "Jsme flexibilní a rozumíme, že plány se mohou změnit. Čas či místo doručení naší {info} můžete změnit ještě v ten samý den do 10:00.",
  "src.components.organisms.Faq.delivery.content8.text1.paradubice":
    "V Pardubicích ti jídlo doručíme v časovém rozpětí 16:30 – 22:00, na konkrétním čase se domluvíš přímo s kurýrem.",
  "src.components.organisms.Faq.delivery.content9.paradubice":
    "Jsme flexibilní a rozumíme, že plány se mohou změnit. Čas či místo doručení naší {info} můžete změnit ještě v ten samý den do 10:00.",
  "src.components.organisms.Faq.delivery.content8.text1.sredni":
    "V okolí Prahy ti jídlo doručíme v časovém rozpětí 17:00 – 22:00, na konkrétním čase se domluvíš přímo s kurýrem.",
  "src.components.organisms.Faq.delivery.content9.sredni":
    "Jsme flexibilní a rozumíme, že plány se mohou změnit. Čas či místo doručení naší {info} můžete změnit ještě v ten samý den do 11:00.",
  "src.components.organisms.Faq.delivery.content8.text1.jablonec":
    "V Jablonci nad Nisou ti jídlo doručíme v časovém rozpětí 18:00 – 22:00, na konkrétním čase se domluvíš přímo s kurýrem.",
  "src.components.organisms.Faq.delivery.content9.jablonec":
    "Jsme flexibilní a rozumíme, že plány se mohou změnit. Čas či místo doručení naší {info} můžete změnit ještě v ten samý den do 10:00.",
  "src.components.organisms.Faq.delivery.content8.text1.mlda":
    "V Mladé Boleslavi ti jídlo doručíme v časovém rozpětí 16:00 – 22:00, na konkrétním čase se domluvíš přímo s kurýrem.",
  "src.components.organisms.Faq.delivery.content9.mlda":
    "Jsme flexibilní a rozumíme, že plány se mohou změnit. Čas či místo doručení naší {info} můžete změnit ještě v ten samý den do 10:00.",
  "src.components.organisms.CityDeliveryBlock.list.description3.sredni":
    "Změnily se ti plány? Čas a místo si můžeš flexibilně změnit ještě v den dovozu do 11:00",
  "src.components.routes.pages.PravilaCookies.title": "PravilaCookies",
  "src.components.organisms.HomePageBanner.title1": "Konečně",
  "src.components.organisms.HomePageBanner.title2": "krabičková dieta,",
  "src.components.organisms.HomePageBanner.title3": "která se opravdu vyplatí!",
  "src.components.molecules.DemoDiscountInfo.promocode": "DEMO11",
  "src.components.molecules.DemoDiscountInfo.percent": "SLEVA 50%",
  "src.components.molecules.DemoDiscountInfo.text": "S PROMOKODEM",
  "src.components.organisms.ComplateMenu.discountText":
    "Super cena s promokódem DEMO11",
  "src.components.molecules.OnlineOrderThiredStepSidebar.previousPrice":
    "Původní cena",
  "src.components.organisms.FaqReferal.title": "Odpovědi na nejčastější otázky",
  "src.components.organisms.FaqReferal.title1":
    "Kde najdu svůj unikátní osobní odkaz?",
  "src.components.organisms.FaqReferal.title2":
    "Kdy a jak můžu svou slevu využít?",
  "src.components.organisms.FaqReferal.title4":
    "Mohu slevu kombinovat s jinými akcemi nebo kódy?",
  "src.components.organisms.FaqReferal.title5": "Kolik přátel mohu doporučit?",
  "src.components.organisms.FaqReferal.title6":
    "Co když můj známý objednávku nezaplatí, ale zruší?",
  "src.components.organisms.FaqReferal.title7": "Na jaké programy sleva platí?",
  "src.components.organisms.FaqReferal.title8":
    "Co když můj kamarád už je vaším klientem?",
  "src.components.organisms.FaqReferal.content1.bold": "Doporučení",
  "src.components.organisms.FaqReferal.content1.info": "zákaznickém portálu",

  "src.components.organisms.FaqReferal.title9":
    "Doporučil jsem vás, ale zapomněl jsem použít svůj unikátní odkaz. Co teď?",
  "src.components.organisms.FaqReferal.content1":
    "Po přihlášení k osobnímu účtu v {liniInfo} hledej sekci {boldInfo}. Tam najdeš odkaz, který stačí zkopírovat a sdílet s přáteli.",
  "src.components.organisms.FaqReferal.content2":
    "Sleva se uplatní automaticky při prodloužení tvého programu NutritionPro. Jedinou podmínkou je, aby objednávka z tvého doporučení byla zaplacená.",
  "src.components.organisms.FaqReferal.content4":
    "Bohužel, slevu za doporučení nelze kombinovat s jinými slevami nebo promo kódy.",
  "src.components.organisms.FaqReferal.content5":
    "Kolik jenom zvládneš, žádné omezení není. Slevu získáš pokaždé, když nový klient objedná a objednávku uhradí.",
  "src.components.organisms.FaqReferal.content6":
    "Potom se žádná sleva nikomu neaktivuje.",
  "src.components.organisms.FaqReferal.content7":
    "Na všechny! Slevu můžeš uplatnit na jakýkoli alespoň 10denní program v nabídce NutritionPro bez ohledu na typ.",
  "src.components.organisms.FaqReferal.content8":
    "Sleva za doporučení se neuplatňuje pro klienty s aktivním programem. Je určena pouze pro nové nebo vracející se zákazníky.",
  "src.components.organisms.FaqReferal.content9":
    "Ačkoli bychom moc chtěli pomoct, technicky to není možné. Vždy se proto ujisti, že známý objednává rozkliknutím tvého unikátního odkazu.",
  "src.components.routes.pages.Referal.title": "Referal",
  "src.components.organisms.HowWorksReferal.title":
    "Co musím pro tu dvojitou tisícovku udělat?",
  "src.components.organisms.HowWorksReferal.step1":
    "V sekci {info} najdeš svůj osobní unikátní odkaz. Prosíme, nezapomeň vyplnit krátký formulář, abychom mohli tvoje doporučení sledovat a dokázali jsme nové objednávky párovat ke tvému účtu.",
  "src.components.organisms.HowWorksReferal.step1.info": "Doporučení",
  "src.components.organisms.HowWorksReferal.step2":
    "Rozkliknutím tvého osobního odkazu získají okamžitou slevu 1000 Kč na jakýkoliv online program delší než 7 dní. Zlobit se určitě nebudou.",
  "src.components.organisms.HowWorksReferal.step3":
    "Jakmile bude doporučená objednávka zaplacena, druhá tisícovka se připíše na tvůj NutritionPro účet (proto musíš být klientem). Automaticky ti potom odečteme 1000 Kč při prodloužení tvého programu. Prostě win-win-win!",
  "src.components.organisms.HowWorksReferal.step4":
    "Můžeš pozvat tolik známých, kolik budeš chtít. Za každé doporučení dostanete slevu 1000 Kč. Oba dva. Automaticky. Bez vytáček.",
  "src.components.organisms.HowWorksReferal.title1":
    "Přihlas se do zákaznického portálu",
  "src.components.organisms.HowWorksReferal.title2":
    "Sdílej odkaz s rodinou a přáteli",
  "src.components.organisms.HowWorksReferal.title3":
    "Užij si slevu při prodloužení programu",
  "src.components.organisms.HowWorksReferal.title4": "Doporučuj dál!",
  "src.components.organisms.forms.referalTitle": "Ozvěte se mi",
  "src.components.organisms.HowWorksReferal.form.title":
    "Máš dotazy nebo nápady?",
  "src.components.organisms.HowWorksReferal.form.description":
    "Pojďme je probrat.",
  "src.components.organisms.ReferalBanner.redPrice":
    "Sdílej radost z krabiček a ušetříš",
  "src.components.organisms.ReferalBanner.btn": "Doporuč nás >",
  "src.components.organisms.ReferalBanner.title1": "Doporuč NutritionPro ",
  "src.components.organisms.ReferalBanner.title2": "známým a oba",
  "src.components.organisms.ReferalBanner.title3": " dostanete 1000 Kč!",
  "src.components.organisms.HowWorksReferal.prihlasit": "Přihlásit se",
  "src.components.organisms.OnlineOrderSecondStep.error.email":
    "Zadejte Vaše email",
  "src.components.organisms.DesktopHeader.referal": "Doporuč nás",
  "src.components.organisms.Footer.akceData": "Pravidla akce „Doporučení“",
  "src.component.molecules.CityLoaction.text": "Lokalita:",
  "src.components.molecules.CityLocation.praha": "Praha",
  "src.components.molecules.CityLocation.brno": "Brno",
  "src.components.molecules.CityLocation.plzen": "Plzeň",
  "src.components.molecules.CityLocation.hradec": "Hradec Králové",
  "src.components.molecules.CityLocation.mlda": "Mladá Boleslav",
  "src.components.molecules.CityLocation.paradubice": "Pardubice",
  "src.components.molecules.CityLocation.jablonec": "Jablonec",
  "src.components.molecules.CityLocation.sredni": "Střední Čechy",
  "src.components.molecules.CityLocation.liberec": "Liberec",
  "src.components.molecules.OnlineMenuSlider.mobile.meat.title": "Classic",
  "src.components.molecules.OnlineMenuSlider.mobile.vege.title": "Vege",
  "src.components.molecules.OnlineMenuSlider.mobile.fish.title": "Bez ryb",
  "src.components.organisms.OrderOnlineFirstStep.peopledescription":
    "Sleva platí pouze na první objednávku",
  "src.components.organisms.OrderOnlineFirstStep.aditionalProduct.title":
    "test",
  "src.components.organisms.OrderOnlineFirstStep.aditionalProduct.description":
    "description",
    "src.components.routes.pages.GiftVoucher.title":"Gift voucher",
    "error.voucherType.required":"requried",
    "error.price.required":"requried"
};
